<template>
  <!-- desktop lista chamados -->
  <div v-if="!$store.state.mobile" class="container-desk">
    <div class="nav-lateral">
      <div class="nav-filtro flex flex-col justify-between">
        <div>
          <filtro />
          <label class="PesquisaChama">
            <input
              key="Pesquis"
              ref="PesquisaChamados"
              class="pesquisa"
              type="text"
              placeholder="Pesquisar"
              @keyup.enter="filtroAply"
              @change="filtroAply"
              v-model="pesquisaLabel"
            />
            <span class="label"></span>
            <span class="bord"></span>
            <feather-icon for="PesquisaChamados" icon="SearchIcon" class="lupa" @click="filtroAply"></feather-icon>
          </label>
        </div>
        <div>
          <div class="flex" v-if="true">
            <vs-button
              class="rounded-full w-full"
              color="primary"
              type="filled"
              icon-pack="feather"
              icon="icon-chevron-right"
              @click="mudaSwitch('criar')"
              icon-after
            >Criar Chamado</vs-button>
          </div>
        </div>
      </div>
      <div class="nav-chamados">
        <listaChamados />
      </div>
    </div>
    <VuePerfectScrollbar class="email-content-scroll-area view ps" :settings="settings">
      <transition name="fade" mode="out-in">
        <chamado v-if="switchOpcao == 'chamado'" />
        <criarChamado v-else-if="switchOpcao == 'criar'" />
        <svgCorreio v-else-if="switchOpcao == 'idle'" />
      </transition>
    </VuePerfectScrollbar>
  </div>
  <!-- mobile lista chamados -->
  <div v-else>
    <listaChamados
      class="nav-chama"
      :style="`height: 100vh; padding-top: calc(14vw + ${$store.state.chromeNavbarHeight}px);`"
    />
  </div>
</template>

<script>
import filtro from "../components/ListaFiltro";
import listaChamados from "../components/ListaChamados";
import chamado from "../components/Chamado.vue";
import svgCorreio from "../components/svgCorreio.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import criarChamado from "../components/ChamadoCriar";
export default {
  components: {
    VuePerfectScrollbar,
    filtro,
    listaChamados,
    chamado,
    svgCorreio,
    criarChamado
  },
  data() {
    return {
      pesquisaLabel: null,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loadingChamado;
      },
      set(value) {
        this.$store.commit("HANDLE", { value, key: "loadingChamado" });
      }
    },
    switchOpcao() {
      return this.$store.state.abaAtiva;
    },
    chamado() {
      return this.$store.state.chamado.chamadoActive;
    }
  },
  methods: {
    filtroAply() {
      this.loading = true;
      this.$store.commit("HANDLE", {
        value: this.pesquisaLabel,
        key: "atualSearchDeBusca"
      });
      this.$store.commit("HANDLE", {
        value: "search",
        key: "parametroDePesquisa"
      });
      this.$store.dispatch("getChamados", {
        search: this.pesquisaLabel
      });
    },
    mudaSwitch(opcao) {
      this.$store.commit("SWITCH_CHAMADO", opcao);
      this.$store.commit("ACTIVE_CHAMADO", null);
    },
    // key() {
    //   var keys = Object.keys(this.$store.state.info.user.access);
    //   // console.log(keys);
    // }
  }
};
</script>

<style lang="scss">
.container-desk {
  display: grid;
  grid-template-columns: 5fr 6fr;
  @include media-down(xl) {
    grid-template-columns: 5fr 5fr;
  }
  grid-template-rows: calc(100vh - 80px);
  grid-template-areas: "nav  view";
  .nav-lateral {
    grid-area: nav;
    background-color: #fff;
    box-shadow: 0px 5px 10px rgba(000, 000, 000, 0.16);
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: "filtro  chamados";
    .nav-filtro {
      grid-area: filtro;
      border-right: 1px #dfdfdf solid;
      padding: 1rem;
    }
    .nav-chamados {
      grid-area: chamados;
      overflow: hidden;
      display: flex;
    }
  }
  .view {
    grid-area: view;
    background-color: #f4f5f7;
    padding: 1.8rem;
    &.active {
      display: flex;
    }
  }
}
.PesquisaChama {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-template-areas: "input  lupa";
  margin: 0 10px;
  .bord {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 90%;
    background: $CorPrimaria;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }
  .lupa {
    grid-area: lupa;
    align-self: center;
    justify-self: center;
  }
  input {
    grid-area: input;
    width: 90%;
    border: 0;
    font-family: inherit;
    height: 48px;
    font-size: 16px;
    background: none;
    border-radius: 0;
    color: $CorSecundaria;
    transition: all 0.15s ease;
    &:focus {
      background: none;
      outline: none;
    }

    &:focus + span + .bord {
      transform: scaleX(1);
    }
  }
}
</style>