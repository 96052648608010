<template>
  <div class="cursor-pointer flex">
    <vs-dropdown vs-custom-content class="cursor-pointer">
      <feather-icon icon="MoreVerticalIcon"></feather-icon>
      <vs-dropdown-menu class="hover-list">
        <li
          v-if="accessAcoes.distribuir"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Distribuir')"
        >
          <span class="ml-2 text-CorPrimaria">Distribuir</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.associar"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Associar')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Associar</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.atender"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Atender')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Atender</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.externo"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Externo')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Externo</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.documentos"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Documentos')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Documentos</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.incluirDocumentos"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Adic. Documentos')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Adic. Documentos</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.interno"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Interno')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Interno</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.solucionado"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Solucionar')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Solucionar</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.encerrar"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Encerrar')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Encerrar</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.reabrir"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Reabrir')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Reabrir</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.cancelar"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Cancelar')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Cancelar</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.reprogramar"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
          @click="openModalAcoes('Reprogramar')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Reprogramar</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
        <li
          v-if="accessAcoes.legenda"
          class="flex justify-between py-2 w-48 px-2 cursor-pointer"
          @click="openModalAcoes('Legendas')"
        >
          <span class="ml-2 hover:text-CorPrimaria">Legendas</span>
          <feather-icon
            icon="ChevronRightIcon"
            class="bg-grey-light rounded-full"
            svgClasses="w-5 h-5 "
          />
        </li>
      </vs-dropdown-menu>
    </vs-dropdown>
    <vs-popup
      v-if="destroyPopup"
      :title="tipoAcao"
      :active.sync="popupActive"
      class="overflow-hidden"
    >
      <popupContent
        :id="id"
        :acao="tipoAcao"
        :chamadoInfo="chamadoInfo"
        @fechar="openModalAcoes('')"
      />
    </vs-popup>
  </div>
</template>

<script>
import popupContent from "./PopupAcoes";
import eventBus from "../eventBus";
export default {
  props: ["id"],
  components: {
    popupContent
  },
  data() {
    return {
      destroyPopup: true,
      popupActive: false,
      tipoAcao: ""
    };
  },
  methods: {
    openModalAcoes(acao) {
      this.tipoAcao = acao;
      this.popupActive = !this.popupActive;
      this.destroyPopup = true;
    }
  },
  watch: {
    popupActive: function(novo) {
      var _this = this;
      if (novo == false) {
        setTimeout(function() {
          _this.destroyPopup = false;
        }, 200);
      } else {
        this.test = false;
      }
    }
  },
  computed: {
    chamadoInfo() {
      return this.$store.state.chamado.chamadoInfo;
    },
    accessAcoes() {
      return this.$store.state.info.user.access;
    }
  }
};
</script>

<style lang="scss">
.hover-list {
  li {
    span {
      &:hover {
        color: $CorPrimaria;
        text-shadow: 0px 3px 5px rgba($color: $CorPrimaria, $alpha: 0.4);
      }
    }
  }
}
</style>