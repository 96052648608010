<template>
  <vs-tabs
    alignment="fixed"
    class="absolute"
    :style="`height: calc((100vh - ${$store.state.chromeNavbarHeight}px) - 14vw); `"
  >
    <vs-tab label="Chamado">
      <div>
        <transition name="acoes">
          <div v-show="activeAcoes" class="fixed w-full bottom-0 left-0 z-50">
            <MobileAcoes
              class="absolute z-10 w-full bottom-0 left-0 rounded-t-lg"
              :id="chamado.id"
            />
          </div>
        </transition>
        <transition name="fade">
          <div
            v-if="activeAcoes"
            class="fixed h-full w-full z-40"
            style="background-color: rgba(000, 000, 000, 0.6);"
            @click="$router.push({ path: '/chamado', query: { ac: false } })"
          ></div>
        </transition>

        <VuePerfectScrollbar
          class="email-content-scroll-area"
          :settings="settings"
          :style="`height: calc((100vh - ${$store.state.chromeNavbarHeight}px) - 18vw); `"
        >
          <div class="flex items-center m-5">
            <!-- botao de açoes -->
            <div
              class="cursor-pointer absolute right-0 top-0 mt-6 mr-3"
              @click="$router.push({ path: '/chamado', query: { ac: true } })"
            >
              <feather-icon icon="MoreVerticalIcon" />
            </div>

            <span>{{parseInt(chamado.id)}} -</span>
            <h1 class="text-20 font-bold cor-secundaria">{{chamado.title}}</h1>
          </div>

          <div>
            <vs-collapse class="p-0 bg-white">
              <vs-collapse-item class="p-0">
                <div slot="header">
                  <div class="flex flex-col">
                    <div class="flex items-center">
                      <h3 class="text-16 font-light cor-baixo-foco mr-2">Autor:</h3>
                      <span class="text-20 font-bold cor-secundaria">{{chamado.author}}</span>
                    </div>

                    <div class="flex items-center">
                      <h3 class="text-16 font-light cor-baixo-foco mr-2">Email:</h3>
                      <span class="text-16">{{chamado.emailAuthor}}</span>
                    </div>

                    <div class="flex items-center">
                      <h3 class="text-16 font-light cor-baixo-foco mr-2">Tel:</h3>
                      <span class="text-16">{{chamado.telephoneAuthor}}</span>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col mb-10">
                  <div class="flex items-center">
                    <h3 class="text-16 font-light cor-baixo-foco mr-2">Analista:</h3>
                    <span class="text-20 font-bold cor-secundaria">{{chamado.analyst}}</span>
                  </div>

                  <div class="flex items-center">
                    <h3 class="text-16 font-light cor-baixo-foco mr-2">Email:</h3>
                    <span class="text-16">{{chamado.emailAnalyst}}</span>
                  </div>

                  <div class="flex items-center">
                    <h3 class="text-16 font-light cor-baixo-foco mr-2">Tel:</h3>
                    <span class="text-16">{{chamado.telephoneAnalyst}}</span>
                  </div>
                </div>
              </vs-collapse-item>
            </vs-collapse>
          </div>

          <div class="flex mt-8 p-8 bg-white flex-column">
            <span class="text-16 cor-baixo-foco">Detalhes:</span>
            <div class="container flex flex-column">
              <div class="p-1 pt-3">
                <span class="text-16 cor-secundaria">Descrição</span>

                <p class="text-16 cor-baixo-foco text-justify">{{chamado.description}}</p>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-wrap justify-center">
                <div
                  v-for="(item, index) in chamado.item"
                  :key="index"
                  class="item flex flex-column m-3 flex-initial"
                >
                  <span class="text-16 cor-secundaria">{{item.title}}</span>
                  <span class="text-16 cor-baixo-foco">{{item.value}}</span>
                </div>
              </div>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </vs-tab>
    <vs-tab label="Interação">
      <div>
        <Chat
          class="email-content-scroll-area"
          :style="`height: calc((100vh - ${$store.state.chromeNavbarHeight}px) - 22vw); `"
          :mensagens="mensagensPublicas"
        />
      </div>
    </vs-tab>
    <vs-tab
      label="Atendimento"
      v-if="accessClient === 'ANALISTA' || accessClient === 'GESTOR SERVICE DESK'"
    >
      <div>
        <atendimento :chamado="chamado" />
      </div>
    </vs-tab>
    <vs-tab
      label="Privado"
      v-if="accessClient === 'ANALISTA' || accessClient === 'GESTOR SERVICE DESK'"
    >
      <div>
        <ChatP
          :style="`height: calc((100vh - ${$store.state.chromeNavbarHeight}px) - 22vw); `"
          :mensagens="mensagensPrivadas"
        />
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Chat from "../components/Chat";
import ChatP from "../components/ChatPrivate";
import atendimento from "../components/atendimento";
import MobileAcoes from "../components/MobileAcoes";
export default {
  components: {
    VuePerfectScrollbar,
    Chat,
    ChatP,
    atendimento,
    MobileAcoes
  },
  data() {
    return {
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
        swipeEasingFriction: 1
      },
      interacaoActive: "Interação",
      activeAcoes: false
    };
  },
  watch: {
    routeParamActiveAcoes: function() {
      var bol = this.routeParamActiveAcoes;
      if (bol) {
        if (bol == true || bol == "true") {
          this.activeAcoes = true;
        } else {
          this.activeAcoes = false;
        }
      } else {
        this.activeAcoes = false;
      }
    }
  },
  computed: {
    mensagensPublicas() {
      return this.$store.state.chamado.chamadoInfo.interactionPublic;
    },
    mensagensPrivadas() {
      return this.$store.state.chamado.chamadoInfo.interactionPrivate;
    },
    chamado() {
      return this.$store.state.chamado.chamadoInfo;
    },
    accessClient() {
      return this.$store.state.info.user.type;
    },
    routeParamActiveAcoes() {
      return this.$route.query.ac;
    }
  },
  methods: {
    mudaSlideInteracao(val) {
      this.interacaoActive = val;
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("MOBILE_CHAMADO_FROM_PAGE", from);
    });
  }
};
</script>
<style lang="scss">
.con-vs-tabs .con-ul-tabs,
.vs-tabs--ul {
  background-color: white !important;
}

.vs-tabs--content {
  padding: 2px 0 0 0 !important;
}
</style>
