<template>
  <lottie
    :options="defaultOptions"
    :height="height"
    :width="width"
    v-on:animCreated="handleAnimation"
  />
</template>

<script>
import Lottie from "../../node_modules/vue-lottie/src/lottie.vue";
import animationData from "./../assets/loading.json";

export default {
  props: {
    width: Number,
    height: Number
  },
  name: "Loading",
  components: {
    lottie: Lottie
  },
  data() {
    return {
      defaultOptions: {
        animationData: animationData,
        autoplay: true,
        loop: true
      },
      animationSpeed: 3
    };
  },
  methods: {
    handleAnimation: function(anim) {
      this.anim = anim;
    },

    stop: function() {
      this.anim.stop();
    },

    play: function() {
      this.anim.play();
    },

    pause: function() {
      this.anim.pause();
    },

    onSpeedChange: function() {
      this.anim.setSpeed(this.animationSpeed);
    }
  }
};
</script>