<template>
  <VuePerfectScrollbar
    class="email-content-scroll-area"
    :settings="settings"
    :style="
      $store.state.mobile
        ? `height: calc((100vh - ${$store.state.chromeNavbarHeight}px) - 14vw);`
        : 'height: calc(100vh -  80px);'
    "
  >
    <div class="flex flex-col md:m-16 m-0">
      <h2 v-if="!isMobile" class="text-3xl cor-baixo-foco">Perfil</h2>
      <div
        class="card-padrao p-10 mx-0 md:m-5 flex flex-col flex-wrap justify-around relative"
        style="margin-top: 76px; transition: all 1s;"
        key="main"
      >
        <div class="absolute right-0 top-0 mt-6 mr-2 md:mt-6 md:mr-6">
          <transition name="fade" mode="out-in">
            <button
              class="btn btn-outline px-4 py-2 md:px-5 md:py-2 text-16"
              type="button"
              v-if="!edit"
              @click="edit = !edit"
            >
              Editar Perfil
            </button>
            <div v-else class="flex">
              <button
                v-if="isValidSenha"
                class="btn btn-confirm px-4 py-2 md:px-5 md:py-2 text-16"
                type="button"
                @click="mudaSenha"
              >
                Confirmar
              </button>
              <button
                v-else
                class="btn btn-confirm px-4 py-2 md:px-5 md:py-2 text-16"
                type="button"
                disabled
              >
                Confirmar
              </button>
              <button
                v-if="!$store.state.mobile"
                class="btn btn-confirm p-2 mx-3"
                type="button"
                @click="mudaSenha('cancel')"
              >
                <feather-icon icon="XIcon" svgClasses="white" />
              </button>
            </div>
          </transition>
        </div>

        <div
          class="flex flex-col self-center items-center"
          style="margin-top: -110px;"
        >
          <vs-avatar
            v-if="edit"
            size="150px"
            icon="photo_camera"
            @click="cropPopup = true"
          />
          <vs-avatar
            v-else
            size="150px"
            :src="userInfo.photo"
            @click="cropPopup = true"
          />
          <span class="text-3xl cor-secundaria">{{ userInfo.nameClient }}</span>
        </div>

        <div class="flex flex-col">
          <h2 class="text-3xl cor-baixo-foco my-5">Informações</h2>
          <div class="flex flex-col md:flex-row flex-wrap justify-between">
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">Nome</h3>
              <span class="text-20 cor-secundaria">{{ userInfo.name }}</span>
            </div>
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">Sobrenome</h3>
              <span class="text-20 cor-secundaria">{{
                userInfo.lastname
              }}</span>
            </div>
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">Empresa</h3>
              <span class="text-20 cor-secundaria">{{ userInfo.company }}</span>
            </div>
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">EMAIL</h3>
              <span class="text-20 cor-secundaria">{{ userInfo.email }}</span>
            </div>
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">Departamento</h3>
              <span class="text-20 cor-secundaria">{{
                userInfo.department
              }}</span>
            </div>
            <div class="s">
              <h3 class="text-16 cor-baixo-foco">Perfil</h3>
              <span class="text-20 cor-secundaria">{{ userInfo.profile }}</span>
            </div>
          </div>
        </div>

        <transition name="fade-up" mode="out-in">
          <div class="flex flex-col" v-if="edit">
            <h2 class="text-3xl cor-baixo-foco my-5">Nova Senha</h2>
            <div class="flex flex-col md:flex-row flex-wrap">
              <div class="w-64">
                <vs-input
                  class="form-popup"
                  label="Senha atual"
                  placeholder=" "
                  size="default"
                  type="password"
                  v-model="senhaAntiga.model"
                  :danger="$v.senhaAntiga.$error"
                  :danger-text="senhaAntiga.danger"
                />
              </div>
              <div class="w-64">
                <vs-input
                  class="form-popup"
                  label="Senha"
                  placeholder=" "
                  size="default"
                  type="password"
                  v-model="senha.model"
                  :danger="$v.senha.$error"
                  :danger-text="senha.danger"
                  :success="!$v.senha.$error && !$v.senha.$invalid"
                  :success-text="senha.success"
                />
              </div>
              <div class="w-64">
                <vs-input
                  class="form-popup"
                  label="Confirmar Senha"
                  placeholder=" "
                  size="default"
                  type="password"
                  v-model="confirmSenha.model"
                  :danger="$v.confirmSenha.$error"
                  :danger-text="confirmSenha.danger"
                />
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div v-if="!native">
        <myupload
          v-if="!isMobile"
          field="img"
          @crop-success="cropSuccess"
          v-model="cropPopup"
          :width="300"
          :height="300"
          url="/upload"
          img-format="png"
        ></myupload>
        <crop
          v-else
          @cropped="cropSuccess"
          v-model="cropPopup"
          :width="300"
          :height="300"
          img-format="png"
        ></crop>
      </div>
    </div>
  </VuePerfectScrollbar>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import myupload from "../components/upload-2";
import crop from "../components/crop/Crop";

const sames = (val, vm) => {
  const senha = vm.this_.senha.model;
  if (val === senha) {
    return true;
  } else {
    return false;
  }
};
export default {
  components: {
    VuePerfectScrollbar,
    myupload,
    crop,
  },
  data() {
    return {
      native: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
      cropPopup: false,

      senhaAntiga: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo.",
      },

      senha: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo.",
      },

      confirmSenha: {
        this_: this,
        model: "",
        danger: "As senhas devem ser iguais.",
        success: "está tudo certo.",
      },
    };
  },
  validations: {
    senhaAntiga: {
      model: {
        required,
      },
    },
    senha: {
      model: {
        required,
      },
    },
    confirmSenha: {
      model: {
        sames,
      },
    },
  },
  methods: {
    cropSuccess(e) {
      // console.log(e);
      this.$store.dispatch("userAttFoto", {
        idUser: this.userInfo.id,
        photo: e,
      });
      this.cropPopup = false;
    },
    mudaSenha(prop) {
      if (prop == "cancel") {
        this.edit = !this.edit;
        this.senha.model = "";
        this.confirmSenha.model = "";
      } else {
        if (this.isValidSenha) {
          this.$store.dispatch("userAttSenha", {
            idUser: this.userInfo.id,
            password: this.senhaAntiga.model,
            newpassword: this.confirmSenha.model,
          });
          this.edit = !this.edit;
        } else {
          this.$v.$touch();
          this.$vs.notify({
            title: "Campos",
            text: "Você precisa preencher os campos corretamente.",
            position: "top-right",
            color: "rgb(242, 19, 93)",
          });
        }
      }
    },
  },
  computed: {
    isValidSenha() {
      return !this.$v.$invalid;
    },
    isMobile() {
      return this.$store.state.mobile;
    },
    userInfo() {
      return this.$store.state.info.user;
    },
    edit: {
      get() {
        return this.$store.state.editPerfilBtn;
      },
      set(val) {
        this.$store.commit("BTN_EDIT_PERFIL_SET", val);
      },
    },
  },
  mounted() {
    if (window.ReactNativeWebView) {
      this.native = true
    }
  },
};
</script>

<style>
.s {
  flex: 1 1 30%;
  margin: 10px;
  display: flex;
  flex-direction: column;
}
</style>
