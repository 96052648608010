<template>
  <div class="chat__bg fixed-height chat-content-area flex items-center justify-center">
    <VuePerfectScrollbar class="chat-content-scroll-area bg" :settings="settings">
      <div class="chat__log" ref="chatLog">
        <ChatP :chatData="mensagens" />
      </div>
    </VuePerfectScrollbar>
    <div class="chat__input flex p-4 bg-white">
      <vs-textarea
        class="flex-1"
        placeholder="Responder"
        v-model="typedMessage"
        style="margin-bottom: 0;"
      />
      <vs-button class="bg-primary-gradient ml-4 h-full" type="filled" @click="sendMsg">Enviar</vs-button>
    </div>
  </div>
</template>

<script>
import ChatP from "./ChatMsgPrivado.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  props: ["mensagens", "chamado"],
  components: {
    VuePerfectScrollbar,
    ChatP
  },
  data() {
    return {
      active: true,
      typedMessage: "",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      },
      clickNotClose: true,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    userAtivo() {
      var name = this.$store.state.info.user.name;
      var lastname = this.$store.state.info.user.lastname;
      return `${name} ${lastname}`;
    }
  },
  methods: {
    sendMsg() {
      if (this.typedMessage.length === 0 || !this.typedMessage.trim()) {
      } else {
        this.$store.dispatch("enviaNovaMensagemParaState", {
          name: this.userAtivo,
          msg: this.typedMessage,
          idTicket: this.chamado.id,
          codeUser: this.$store.state.info.user.id,
          emailUser: this.$store.state.info.user.email,
          status: "",
          type: "private"
        });
        this.typedMessage = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg {
  background-color: #dfdfdf !important;
}
</style>