<template>
    <vs-popup :active.sync="active3" title="Nova foto">
        <div class="flex w-full justify-center mb-4" v-if="step == 1">
            <div
                class="crp-drop-area p-10 flex justify-center items-center"
                @dragleave="preventDefault"
                @dragover="preventDefault"
                @dragenter="preventDefault"
                @click="handleClick"
                @drop="handleChange"
            >
                <i class="crp-icon1" v-show="loading != 1">
                    <i class="crp-icon1-arrow"></i>
                    <i class="crp-icon1-body"></i>
                    <i class="crp-icon1-bottom"></i>
                </i>
                <span class="crp-hint" v-show="loading !== 1">Clique aqui para carregar</span>
                <span class="crp-no-supported-hint" v-show="!isSupported"
                    >Browser não suportado, use o IE10+ ou outro browser</span
                >
                <input type="file" v-show="false" @change="handleChange" ref="fileinput" />
            </div>
            <div class="crp-error" v-show="hasError"><i class="crp-icon2"></i> {{ errorMsg }}</div>
        </div>

        <div class="flex flex-col" v-if="step == 2">
            <div class="flex flex-col md:flex-row justify-center m-3">
                <div class="flex flex-col justify-center items-center m-3">
                    <div class="crp-img-container">
                        <img
                            :src="sourceImgUrl"
                            :style="sourceImgStyle"
                            class="crp-img"
                            draggable="false"
                            @drag="preventDefault"
                            @dragstart="preventDefault"
                            @dragend="preventDefault"
                            @dragleave="preventDefault"
                            @dragover="preventDefault"
                            @dragenter="preventDefault"
                            @drop="preventDefault"
                            @touchstart="imgStartMove"
                            @touchmove="imgMove"
                            @touchend="createImg"
                            @touchcancel="createImg"
                            @mousedown="imgStartMove"
                            @mousemove="imgMove"
                            @mouseup="createImg"
                            @mouseout="createImg"
                            ref="img"
                        />
                        <div class="crp-img-shade crp-img-shade-1" :style="sourceImgShadeStyle"></div>
                        <div class="crp-img-shade crp-img-shade-2" :style="sourceImgShadeStyle"></div>
                    </div>

                    <div class="crp-range">
                        <input type="range" :value="scale.range" step="1" min="0" max="100" @mousemove="zoomChange" />
                        <i @mousedown="startZoomSub" @mouseout="endZoomSub" @mouseup="endZoomSub" class="crp-icon5"></i>
                        <i @mousedown="startZoomAdd" @mouseout="endZoomAdd" @mouseup="endZoomAdd" class="crp-icon6"></i>
                    </div>

                    <div class="crp-rotate" v-if="!noRotate">
                        <i @click="rotateImg">↻</i>
                    </div>
                </div>

                <div class="flex justify-center m-3">
                    <div class="crp-preview">
                        <div class="crp-preview-item" v-if="!noSquare">
                            <img :src="createImgUrl" :style="previewStyle" />
                            <span>Pré-visualizar</span>
                        </div>
                        <div class="crp-preview-item circle" v-if="!noCircle">
                            <img :src="createImgUrl" :style="previewStyle" />
                            <span>Pré-visualizar</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex w-full justify-between mt-4">
                <vs-button class="w-5/12 md:w-4/12" @click="setStep(1)" @mousedown="ripple">Voltar</vs-button>
                <vs-button class="w-5/12 md:w-4/12" @click="completionCropProcess" @mousedown="ripple"
                    >Salvar</vs-button
                >
            </div>
        </div>

        <canvas v-show="false" :width="width" :height="height" ref="canvas"></canvas>
    </vs-popup>
</template>

<script>
/* eslint-disable */
'use strict';
import language from '../utils/language.js';
import mimes from '../utils/mimes.js';
import data2blob from '../utils/data2blob.js';
import effectRipple from '../utils/effectRipple.js';

export default {
    props: {
        value: {
            default: true,
        },

        width: {
            type: Number,
            default: 200,
        },

        height: {
            type: Number,
            default: 200,
        },

        noRotate: {
            type: Boolean,
            default: true,
        },

        noCircle: {
            type: Boolean,
            default: false,
        },

        noSquare: {
            type: Boolean,
            default: false,
        },

        maxSize: {
            type: Number,
            default: 10240,
        },

        imgFormat: {
            type: String,
            default: 'png',
        },

        imgBgc: {
            type: String,
            default: '#fff',
        },
    },
    data() {
        let that = this,
            { imgFormat, langType, langExt, width, height } = that,
            isSupported = true,
            allowImgFormat = ['jpg', 'png'],
            tempImgFormat = allowImgFormat.indexOf(imgFormat) === -1 ? 'jpg' : imgFormat,
            lang = language,
            mime = mimes[tempImgFormat];
        // Formato de imagem padrão
        that.imgFormat = tempImgFormat;

        if (typeof FormData != 'function') {
            isSupported = false;
        }
        return {
            active3: false,

            // Imagem mime
            mime,

            // Pacote de linguagem
            lang,

            // Se o navegador suporta o controle
            isSupported,
            // Se o navegador suporta eventos da tela de toque
            isSupportTouch: document.hasOwnProperty('ontouchstart'),

            step: 1, // 1 arquivo selecionado 2 corte 3 upload

            // Upload status e progresso
            loading: 0, // 0 não iniciado 1 em andamento 2 com êxito 3 erro
            progress: 0,

            // se existem erros e mensagens de erro
            hasError: false,
            errorMsg: '',

            // apectratio do mapa de demanda
            ratio: width / height,

            // Endereço da imagem original, endereço da imagem gerada
            sourceImg: null,
            sourceImgUrl: '',
            createImgUrl: '',

            // O valor inicial do evento de arrasto da imagem original
            sourceImgMouseDown: {
                on: false,
                mX: 0, //As coordenadas do pressionamento do mouse
                mY: 0,
                x: 0, //scale coordenadas originais
                y: 0,
            },

            // O tamanho do contêiner que gera a visualização da imagem
            previewContainer: {
                width: 100,
                height: 100,
            },

            // Largura e altura do contêiner da imagem original
            sourceImgContainer: {
                // sic
                width: 240,
                height: 184, // Se a escala da imagem gerada for consistente com isso, haverá um erro, altere-o para um formato especial primeiro, hahaha
            },

            // Atributos de exibição da imagem original
            scale: {
                zoomAddOn: false, //Evento de zoom do botão ativado
                zoomSubOn: false, //Evento de zoom do botão ativado
                range: 1, //Max 100

                x: 0,
                y: 0,
                width: 0,
                height: 0,
                maxWidth: 0,
                maxHeight: 0,
                minWidth: 0, //mais ampla
                minHeight: 0,
                naturalWidth: 0, //Largura original
                naturalHeight: 0,
            },
        };
    },
    computed: {
        // Estilo da barra de progresso
        progressStyle() {
            let { progress } = this;
            return {
                width: progress + '%',
            };
        },
        // Estilo original
        sourceImgStyle() {
            let { scale, sourceImgMasking } = this,
                top = scale.y + sourceImgMasking.y + 'px',
                left = scale.x + sourceImgMasking.x + 'px';
            return {
                top,
                left,
                width: scale.width + 'px',
                height: scale.height + 'px', // Opera compatível
                'max-width': 'none',
            };
        },
        // Atributos da máscara original
        sourceImgMasking() {
            let { width, height, ratio, sourceImgContainer } = this,
                sic = sourceImgContainer,
                sicRatio = sic.width / sic.height, // Proporção da imagem do recipiente da imagem original
                x = 0,
                y = 0,
                w = sic.width,
                h = sic.height,
                scale = 1;
            if (ratio < sicRatio) {
                scale = sic.height / height;
                w = sic.height * ratio;
                x = (sic.width - w) / 2;
            }
            if (ratio > sicRatio) {
                scale = sic.width / width;
                h = sic.width / ratio;
                y = (sic.height - h) / 2;
            }

            return {
                scale, // A escala relativa da largura e altura da máscara
                x,
                y,
                width: w,
                height: h,
            };
        },
        // Estilo de máscara original
        sourceImgShadeStyle() {
            let { sourceImgMasking, sourceImgContainer } = this,
                sic = sourceImgContainer,
                sim = sourceImgMasking,
                w = sim.width == sic.width ? sim.width : (sic.width - sim.width) / 2,
                h = sim.height == sic.height ? sim.height : (sic.height - sim.height) / 2;
            return {
                width: w + 'px',
                height: h + 'px',
            };
        },
        previewStyle() {
            let { width, height, ratio, previewContainer } = this,
                pc = previewContainer,
                w = pc.width,
                h = pc.height,
                pcRatio = w / h;
            if (ratio < pcRatio) {
                w = pc.height * ratio;
            }
            if (ratio > pcRatio) {
                h = pc.width / ratio;
            }
            return {
                width: w + 'px',
                height: h + 'px',
            };
        },
    },
    watch: {
        value(newValue) {
            if (newValue && this.loading != 1) {
                this.reset();
            }
            this.active3 = newValue;
        },
        active3(newValue) {
            this.$emit('input', newValue);
        }
    },
    methods: {
        // Clique em efeito cascata
        ripple(e) {
            effectRipple(e);
        },
        // Fechar controle
        off() {
            setTimeout(() => {
                this.$emit('input', false);
                this.active3 = false;
                if (this.step == 3 && this.loading == 2) {
                    this.setStep(1);
                }
            }, 200);
        },
        // Etapas de configuração
        setStep(no) {
            // O atraso é para mostrar o efeito de animação, hahaha
            setTimeout(() => {
                this.step = no;
            }, 200);
        },

        /* Ligação da função da área de seleção de imagem
		 ---------------------------------------------------------------*/
        preventDefault(e) {
            e.preventDefault();
            return false;
        },
        handleClick(e) {
            if (this.loading !== 1) {
                if (e.target !== this.$refs.fileinput) {
                    e.preventDefault();
                    if (document.activeElement !== this.$refs) {
                        this.$refs.fileinput.click();
                    }
                }
            }
        },
        handleChange(e) {
            e.preventDefault();
            if (this.loading !== 1) {
                let files = e.target.files || e.dataTransfer.files;
                this.reset();
                if (this.checkFile(files[0])) {
                    this.setSourceImg(files[0]);
                }
            }
        },
        /* ---------------------------------------------------------------*/

        // Verifique se o arquivo selecionado é adequado
        checkFile(file) {
            let that = this,
                { lang, maxSize } = that;
            // Apenas imagem
            if (file.type.indexOf('image') === -1) {
                that.hasError = true;
                that.errorMsg = lang.error.onlyImg;
                return false;
            }

            // Tamanho grande
            if (file.size / 1024 > maxSize) {
                that.hasError = true;
                that.errorMsg = lang.error.outOfSize + maxSize + 'kb';
                return false;
            }
            return true;
        },
        // Redefinir controle
        reset() {
            let that = this;
            that.loading = 0;
            that.hasError = false;
            that.errorMsg = '';
            that.progress = 0;
        },
        // Definir fonte de imagem
        setSourceImg(file) {
            this.$emit('src-file-set', file.name, file.type, file.size);
            let that = this,
                fr = new FileReader();
            fr.onload = function (e) {
                that.sourceImgUrl = fr.result;
                that.startCrop();
            };
            fr.readAsDataURL(file);
        },
        // Preparação antes do corte
        startCrop() {
            let that = this,
                { width, height, ratio, scale, sourceImgUrl, sourceImgMasking, lang } = that,
                sim = sourceImgMasking,
                img = new Image();
            img.src = sourceImgUrl;
            img.onload = function () {
                let nWidth = img.naturalWidth,
                    nHeight = img.naturalHeight,
                    nRatio = nWidth / nHeight,
                    w = sim.width,
                    h = sim.height,
                    x = 0,
                    y = 0;
                // Os pixels da imagem não estão no padrão
                if (nWidth < width || nHeight < height) {
                    that.hasError = true;
                    that.errorMsg = lang.error.lowestPx + width + '*' + height;
                    return false;
                }
                if (ratio > nRatio) {
                    h = w / nRatio;
                    y = (sim.height - h) / 2;
                }
                if (ratio < nRatio) {
                    w = h * nRatio;
                    x = (sim.width - w) / 2;
                }
                scale.range = 0;
                scale.x = x;
                scale.y = y;
                scale.width = w;
                scale.height = h;
                scale.minWidth = w;
                scale.minHeight = h;
                scale.maxWidth = nWidth * sim.scale;
                scale.maxHeight = nHeight * sim.scale;
                scale.naturalWidth = nWidth;
                scale.naturalHeight = nHeight;
                that.sourceImg = img;
                that.createImg();
                that.setStep(2);
            };
        },
        // O mouse pressiona a imagem para mover
        imgStartMove(e) {
            e.preventDefault();
            // Suporte a eventos de toque, eventos de mouse são inválidos
            if (this.isSupportTouch && !e.targetTouches) {
                return false;
            }
            let et = e.targetTouches ? e.targetTouches[0] : e,
                { sourceImgMouseDown, scale } = this,
                simd = sourceImgMouseDown;
            simd.mX = et.screenX;
            simd.mY = et.screenY;
            simd.x = scale.x;
            simd.y = scale.y;
            simd.on = true;
        },
        // Mova-se quando o mouse é pressionado, a imagem se move
        imgMove(e) {
            e.preventDefault();
            // Suporte a eventos de toque, eventos de mouse são inválidos
            if (this.isSupportTouch && !e.targetTouches) {
                return false;
            }
            let et = e.targetTouches ? e.targetTouches[0] : e,
                {
                    sourceImgMouseDown: { on, mX, mY, x, y },
                    scale,
                    sourceImgMasking,
                } = this,
                sim = sourceImgMasking,
                nX = et.screenX,
                nY = et.screenY,
                dX = nX - mX,
                dY = nY - mY,
                rX = x + dX,
                rY = y + dY;
            if (!on) return;
            if (rX > 0) {
                rX = 0;
            }
            if (rY > 0) {
                rY = 0;
            }
            if (rX < sim.width - scale.width) {
                rX = sim.width - scale.width;
            }
            if (rY < sim.height - scale.height) {
                rY = sim.height - scale.height;
            }
            scale.x = rX;
            scale.y = rY;
        },
        // Gire a imagem no sentido horário
        rotateImg(e) {
            let {
                    sourceImg,
                    scale: { naturalWidth, naturalHeight },
                } = this,
                width = naturalHeight,
                height = naturalWidth,
                canvas = this.$refs.canvas,
                ctx = canvas.getContext('2d');
            canvas.width = width;
            canvas.height = height;
            ctx.clearRect(0, 0, width, height);

            ctx.fillStyle = 'rgba(0,0,0,0)';
            ctx.fillRect(0, 0, width, height);

            ctx.translate(width, 0);
            ctx.rotate((Math.PI * 90) / 180);

            ctx.drawImage(sourceImg, 0, 0, naturalWidth, naturalHeight);
            let imgUrl = canvas.toDataURL(mimes['png']);

            this.sourceImgUrl = imgUrl;
            this.startCrop();
        },

        // Pressione o botão para iniciar o zoom
        startZoomAdd(e) {
            let that = this,
                { scale } = that;
            scale.zoomAddOn = true;

            function zoom() {
                if (scale.zoomAddOn) {
                    let range = scale.range >= 100 ? 100 : ++scale.range;
                    that.zoomImg(range);
                    setTimeout(function () {
                        zoom();
                    }, 60);
                }
            }
            zoom();
        },
        // Solte o botão ou afaste-se para cancelar o zoom
        endZoomAdd(e) {
            this.scale.zoomAddOn = false;
        },
        // Pressione o botão para diminuir o zoom
        startZoomSub(e) {
            let that = this,
                { scale } = that;
            scale.zoomSubOn = true;

            function zoom() {
                if (scale.zoomSubOn) {
                    let range = scale.range <= 0 ? 0 : --scale.range;
                    that.zoomImg(range);
                    setTimeout(function () {
                        zoom();
                    }, 60);
                }
            }
            zoom();
        },
        // Solte o botão ou afaste-se para cancelar o zoom
        endZoomSub(e) {
            let { scale } = this;
            scale.zoomSubOn = false;
        },
        zoomChange(e) {
            this.zoomImg(e.target.value);
        },
        // Ampliar a imagem original
        zoomImg(newRange) {
            let that = this,
                { sourceImgMasking, sourceImgMouseDown, scale } = this,
                { maxWidth, maxHeight, minWidth, minHeight, width, height, x, y, range } = scale,
                sim = sourceImgMasking,
                // Largura e altura da máscara
                sWidth = sim.width,
                sHeight = sim.height,
                // Nova largura e altura
                nWidth = minWidth + ((maxWidth - minWidth) * newRange) / 100,
                nHeight = minHeight + ((maxHeight - minHeight) * newRange) / 100,
                // Novas coordenadas (dimensionadas de acordo com o ponto central da máscara)
                nX = sWidth / 2 - (nWidth / width) * (sWidth / 2 - x),
                nY = sHeight / 2 - (nHeight / height) * (sHeight / 2 - y);

            // Determine se as novas coordenadas excedem o limite da máscara
            if (nX > 0) {
                nX = 0;
            }
            if (nY > 0) {
                nY = 0;
            }
            if (nX < sWidth - nWidth) {
                nX = sWidth - nWidth;
            }
            if (nY < sHeight - nHeight) {
                nY = sHeight - nHeight;
            }

            // Processamento de atribuição
            scale.x = nX;
            scale.y = nY;
            scale.width = nWidth;
            scale.height = nHeight;
            scale.range = newRange;
            setTimeout(function () {
                if (scale.range == newRange) {
                    that.createImg();
                }
            }, 300);
        },
        // Gere imagens de demanda
        createImg(e) {
            let that = this,
                {
                    imgFormat,
                    imgBgc,
                    mime,
                    sourceImg,
                    scale: { x, y, width, height },
                    sourceImgMasking: { scale },
                } = that,
                canvas = that.$refs.canvas,
                ctx = canvas.getContext('2d');
            if (e) {
                // Cancelar o movimento do mouse para baixo
                that.sourceImgMouseDown.on = false;
            }
            canvas.width = that.width;
            canvas.height = that.height;
            ctx.clearRect(0, 0, that.width, that.height);

            if (imgFormat == 'png') {
                ctx.fillStyle = 'rgba(0,0,0,0)';
            } else {
                // Se jpg define o plano de fundo para uma área transparente, o padrão é branco
                ctx.fillStyle = imgBgc;
            }
            ctx.fillRect(0, 0, that.width, that.height);

            ctx.drawImage(sourceImg, x / scale, y / scale, width / scale, height / scale);
            that.createImgUrl = canvas.toDataURL(mime);
        },
        completionCropProcess() {
            let { url, createImgUrl, field, ki } = this;

            this.$emit('cropped', createImgUrl, field);
            this.off();
        },
    },
    created() {
        // Botão de ligação esc para ocultar este evento de plug-in
        document.addEventListener('keyup', (e) => {
            if (this.value && (e.key == 'Escape' || e.keyCode == 27)) {
                this.off();
            }
        });
    },
};
</script>

<style lang="scss">
@charset "UTF-8";
@-webkit-keyframes crp_progress {
    0% {
        background-position-y: 0;
    }
    100% {
        background-position-y: 40px;
    }
}
@keyframes crp_progress {
    0% {
        background-position-y: 0;
    }
    100% {
        background-position-y: 40px;
    }
}

@-webkit-keyframes crp {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translatey(-60px);
        transform: scale(0) translatey(-60px);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1) translatey(0);
        transform: scale(1) translatey(0);
    }
}

@keyframes crp {
    0% {
        opacity: 0;
        -webkit-transform: scale(0) translatey(-60px);
        transform: scale(0) translatey(-60px);
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1) translatey(0);
        transform: scale(1) translatey(0);
    }
}

.vs-dialog__content {
    margin-bottom: 0px !important;
}

.crp-drop-area {
    background-color: rgba(0, 0, 0, 0.03);
    border: 1px dashed rgba(0, 0, 0, 0.08);
    border-radius: 10px;
}
.crp-icon1 {
    display: block;
    margin: 0 auto 6px;
    width: 42px;
    height: 42px;
    overflow: hidden;
}
.crp-icon1 .crp-icon1-arrow {
    display: block;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-bottom: 14.7px solid rgba(0, 0, 0, 0.3);
    border-left: 14.7px solid transparent;
    border-right: 14.7px solid transparent;
}
.crp-icon1 .crp-icon1-body {
    display: block;
    width: 12.6px;
    height: 14.7px;
    margin: 0 auto;
    background-color: rgba(0, 0, 0, 0.3);
}
.crp-icon1 .crp-icon1-bottom {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    height: 12.6px;
    border: 6px solid rgba(0, 0, 0, 0.3);
    border-top: none;
}
.crp-hint {
    display: block;
    padding: 15px;
    font-size: 14px;
    color: #666;
    line-height: 30px;
}
.crp-no-supported-hint {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    height: 60px;
    line-height: 30px;
    background-color: #eee;
    text-align: center;
    color: #666;
    font-size: 14px;
}
.crp-drop-area:hover {
    cursor: pointer;
    border-color: rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.05);
}

.crp-img-container {
    position: relative;
    display: block;
    width: 240px;
    height: 180px;
    background-color: #e5e5e0;
    overflow: hidden;
    .crp-img {
        position: absolute;
        display: block;
        cursor: move;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .crp-img-shade {
        -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
        position: absolute;
        background-color: rgba(241, 242, 243, 0.8);
        &.crp-img-shade-1 {
            top: 0;
            left: 0;
        }
        &.crp-img-shade-2 {
            bottom: 0;
            right: 0;
        }
    }
}

.crp-rotate {
    position: relative;
    width: 240px;
    height: 18px;
    i {
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        line-height: 18px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        background-color: rgba(0, 0, 0, 0.08);
        color: #fff;
        overflow: hidden;
        &:hover {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.14);
        }
        &:first-child {
            float: left;
        }
        &:last-child {
            float: right;
        }
    }
}
.crp-range {
    position: relative;
    margin: 30px 0 10px 0;
    width: 240px;
    height: 18px;
    input[type='range'] {
        display: block;
        padding-top: 5px;
        margin: 0 auto;
        width: 180px;
        height: 8px;
        vertical-align: top;
        background: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        cursor: pointer;
        &::-webkit-slider-thumb {
            -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
            -webkit-appearance: none;
            appearance: none;
            margin-top: -3px;
            width: 12px;
            height: 12px;
            background-color: #61c091;
            border-radius: 100%;
            border: none;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }
        &::-moz-range-thumb {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
            -moz-appearance: none;
            appearance: none;
            width: 12px;
            height: 12px;
            background-color: #61c091;
            border-radius: 100%;
            border: none;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }
        &::-ms-thumb {
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.18);
            appearance: none;
            width: 12px;
            height: 12px;
            background-color: #61c091;
            border: none;
            border-radius: 100%;
            -webkit-transition: 0.2s;
            transition: 0.2s;
        }
        &::-webkit-slider-runnable-track {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            width: 100%;
            height: 6px;
            cursor: pointer;
            border-radius: 2px;
            border: none;
            background-color: rgba(68, 170, 119, 0.3);
        }
        &::-moz-range-track {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            width: 100%;
            height: 6px;
            cursor: pointer;
            border-radius: 2px;
            border: none;
            background-color: rgba(68, 170, 119, 0.3);
        }
        &::-ms-track {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            width: 100%;
            cursor: pointer;
            background: transparent;
            border-color: transparent;
            color: transparent;
            height: 6px;
            border-radius: 2px;
            border: none;
        }
        &::-ms-fill-lower {
            background-color: rgba(68, 170, 119, 0.3);
        }
        &::-ms-fill-upper {
            background-color: rgba(68, 170, 119, 0.15);
        }
        &:active {
            &::-moz-range-thumb {
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
                width: 14px;
                height: 14px;
            }
            &::-ms-thumb {
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
                width: 14px;
                height: 14px;
            }
            &::-webkit-slider-thumb {
                -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
                box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.23);
                margin-top: -4px;
                width: 14px;
                height: 14px;
            }
        }
        &:focus {
            outline: none;
            &::-webkit-slider-runnable-track {
                background-color: rgba(68, 170, 119, 0.5);
            }
            &::-moz-range-track {
                background-color: rgba(68, 170, 119, 0.5);
            }
            &::-ms-fill-lower {
                background-color: rgba(68, 170, 119, 0.45);
            }
            &::-ms-fill-upper {
                background-color: rgba(68, 170, 119, 0.25);
            }
        }
    }
    .crp-icon5 {
        position: absolute;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.08);
        left: 0;
        &:hover {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.14);
        }
        &::before {
            position: absolute;
            content: '';
            display: block;
            left: 3px;
            top: 8px;
            width: 12px;
            height: 2px;
            background-color: #fff;
        }
    }
    .crp-icon6 {
        position: absolute;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.08);
        right: 0;
        &:hover {
            -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12);
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.14);
        }
        &::before {
            position: absolute;
            content: '';
            display: block;
            left: 3px;
            top: 8px;
            width: 12px;
            height: 2px;
            background-color: #fff;
        }
        &::after {
            position: absolute;
            content: '';
            display: block;
            top: 3px;
            left: 8px;
            width: 2px;
            height: 12px;
            background-color: #fff;
        }
    }
}

.crp-preview-item {
    position: relative;
    padding: 5px;
    width: 100px;
    height: 100px;
    float: left;
    margin-right: 16px;
    span {
        position: absolute;
        bottom: -30px;
        width: 100%;
        font-size: 14px;
        color: #bbb;
        display: block;
        text-align: center;
    }
    img {
        position: absolute;
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        padding: 3px;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        overflow: hidden;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    &.circle {
        margin-right: 0;
        img {
            border-radius: 100%;
        }
    }
}

.crp-step3 .crp-upload {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 35px;
    height: 170px;
    background-color: rgba(0, 0, 0, 0.03);
    text-align: center;
    border: 1px dashed #ddd;
}
.crp-step3 .crp-upload .crp-loading {
    display: block;
    padding: 15px;
    font-size: 16px;
    color: #999;
    line-height: 30px;
}
.crp-step3 .crp-upload .crp-progress-wrap {
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.08);
    border-radius: 3px;
}
.crp-step3 .crp-upload .crp-progress-wrap .crp-progress {
    position: relative;
    display: block;
    height: 5px;
    border-radius: 3px;
    background-color: #4a7;
    -webkit-box-shadow: 0 2px 6px 0 rgba(68, 170, 119, 0.3);
    box-shadow: 0 2px 6px 0 rgba(68, 170, 119, 0.3);
    -webkit-transition: width 0.15s linear;
    transition: width 0.15s linear;
    background-image: -webkit-linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
    background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
    );
    background-size: 40px 40px;
    -webkit-animation: crp_progress 0.5s linear infinite;
    animation: crp_progress 0.5s linear infinite;
}
.crp-step3 .crp-upload .crp-progress-wrap .crp-progress::after {
    content: '';
    position: absolute;
    display: block;
    top: -3px;
    right: -3px;
    width: 9px;
    height: 9px;
    border: 1px solid rgba(245, 246, 247, 0.7);
    -webkit-box-shadow: 0 1px 4px 0 rgba(68, 170, 119, 0.7);
    box-shadow: 0 1px 4px 0 rgba(68, 170, 119, 0.7);
    border-radius: 100%;
    background-color: #4a7;
}
.crp-step3 .crp-upload .crp-error,
.crp-step3 .crp-upload .crp-success {
    height: 100px;
    line-height: 100px;
}
.crp-operate {
    position: absolute;
    right: 20px;
    bottom: 20px;
}
.crp-operate a {
    position: relative;
    float: left;
    display: block;
    margin-left: 10px;
    width: 100px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    color: #4a7;
    border-radius: 2px;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.crp-operate a:hover {
    background-color: rgba(0, 0, 0, 0.03);
}
.crp-error,
.crp-success {
    display: block;
    font-size: 14px;
    line-height: 24px;
    height: 24px;
    color: #d10;
    text-align: center;
    vertical-align: top;
}
.crp-success {
    color: #4a7;
}
.crp-icon3 {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 4px;
}
.crp-icon3::after {
    position: absolute;
    top: 3px;
    left: 6px;
    width: 6px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-color: #4a7;
    border-style: solid;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    content: '';
}
.crp-icon2 {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    top: 4px;
}
.crp-icon2::after,
.crp-icon2::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 4px;
    width: 13px;
    height: 2px;
    background-color: #d10;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.crp-icon2::after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.e-ripple {
    position: absolute;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.15);
    background-clip: padding-box;
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    opacity: 1;
}
.e-ripple.z-active {
    opacity: 0;
    -webkit-transform: scale(2);
    -ms-transform: scale(2);
    transform: scale(2);
    -webkit-transition: opacity 1.2s ease-out, -webkit-transform 0.6s ease-out;
    transition: opacity 1.2s ease-out, -webkit-transform 0.6s ease-out;
    transition: opacity 1.2s ease-out, transform 0.6s ease-out;
    transition: opacity 1.2s ease-out, transform 0.6s ease-out, -webkit-transform 0.6s ease-out;
}
</style>
