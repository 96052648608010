import Vue from 'vue'
import Router from 'vue-router'
import RD from './views/ResumoDados.vue'
import Login from './views/Login.vue'
import chamados from './views/Chamados.vue'
import perfil from './views/Perfil.vue'
import ChamadoMobile from './views/MobileChamado.vue'
import Root from './views/AppRoot'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '',
      redirect: '/resumodados',
      component: Root,
      children: [{
        path: '/resumodados',
        name: 'Resumo de Dados',
        component: RD,
        meta: {
          login: true,
        },
      }, {
        path: 'perfil',
        name: 'Perfil',
        component: perfil,
        meta: {
          login: true,
        },
      },
      {
        path: '/servicos/:nome',
        name: 'chamados',
        meta: {
          login: true,
          pesquisa: true,
          filtro: true
        },
        component: chamados,
        // children: []
      }, {
        path: 'chamado',
        name: 'MobileChamado',
        component: ChamadoMobile,
        meta: {
          login: true,
          pesquisa: false,
          filtro: false,
          voltarIcon: true
        },
        beforeEnter: (to, from, next) => {
          if (store.state.mobile) {
            next()
          } else {
            next('/')
          }
        }
      }]
    }, {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: (to, from, next) => {
        store.commit("SAIR_LOGIN");
        next();
      }
    },

  ]
});

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.login)) {

    if (!window.localStorage.d) {
      next(router.replace({ path: '/login', query: { to: to.path } }));
    } else {
      next();
    }

  } else {
    next()
  }

});


export default router;
