<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 566.258 229.887">
    <g transform="translate(-71.763 -64.146)" opacity=".61">
      <path
        data-name="Caminho 69"
        d="M267.929 193.015v-62.339h-59.573a8.983 8.983 0 0 0-9.064 7.871 8.732 8.732 0 0 0 8.689 9.589h29.233a6.029 6.029 0 0 1 5.991 4.719 5.893 5.893 0 0 1-5.775 7.054h-14.107a8.985 8.985 0 0 0-9.066 7.872 8.733 8.733 0 0 0 8.689 9.587h15.108a5.175 5.175 0 0 1 5.238 4.466 4.98 4.98 0 0 1-4.953 5.492h-15.016a8.985 8.985 0 0 0-9.066 7.873 8.733 8.733 0 0 0 8.689 9.587h25.368a5.766 5.766 0 0 1 5.765 5.765 5.764 5.764 0 0 1-5.765 5.763h-52.762a8.983 8.983 0 0 0-9.064 7.873 8.73 8.73 0 0 0 8.689 9.587h92.383a5.9 5.9 0 0 1 5.867 4.622 5.769 5.769 0 0 1-5.655 6.905h-35.35a8.985 8.985 0 0 0-9.064 7.874 8.734 8.734 0 0 0 8.689 9.587h69.663v-69.747z"
        fill="#e8b674"
      />
      <path data-name="Retângulo 40" fill="#ffac24" d="M321.903 205.72h14.23v88.313h-14.23z" />
      <path
        data-name="Caminho 70"
        d="M329.019 294.033V233.94a4.536 4.536 0 0 1 4.536-4.536h2.58v-23.683h-14.232v88.312z"
        fill="#ff9e00"
      />
      <path
        data-name="Caminho 71"
        d="M394.875 201.303v-90.321H292.31a42.536 42.536 0 0 0-42.535 42.535v42.619a9.523 9.523 0 0 0 1.533 5.167z"
        fill="#ebb66b"
      />
      <path
        data-name="Caminho 72"
        d="M250.843 144.154a42.516 42.516 0 0 0-1.068 9.363v42.619a9.586 9.586 0 0 0 9.587 9.585h135.513v-61.567z"
        fill="#fed18e"
      />
      <path
        data-name="Caminho 73"
        d="M394.875 201.033v-19.139h-145.1v14.242a9.5 9.5 0 0 0 1.387 4.9z"
        fill="#f2bc6b"
      />
      <path
        data-name="Retângulo 41"
        d="M372.065 110.983h45.622a19.724 19.724 0 0 1 19.724 19.724v70.283h-85.07v-70.283a19.724 19.724 0 0 1 19.724-19.724z"
        fill="#ff9e00"
      />
      <path
        data-name="Caminho 74"
        d="M480.2 198.046H249.967a9.589 9.589 0 0 0 9.393 7.675H480.2a3.838 3.838 0 0 0 0-7.675z"
        fill="#e6ae65"
      />
      <circle
        data-name="Elipse 22"
        cx="5.182"
        cy="5.182"
        r="5.182"
        transform="translate(438.699 101.568)"
        stroke-width="1.5"
        stroke="#f2bc6b"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <circle
        data-name="Elipse 23"
        cx="5.182"
        cy="5.182"
        r="5.182"
        transform="translate(186.447 195.808)"
        stroke-width="1.5"
        stroke="#ebb66b"
        stroke-linecap="round"
        stroke-linejoin="round"
        fill="none"
      />
      <g
        data-name="Grupo 119"
        fill="none"
        stroke="#ff9e00"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      >
        <path data-name="Linha 25" d="M262.937 86.287v12.22" />
        <path data-name="Linha 26" d="M269.047 92.397h-12.22" />
      </g>
      <g
        data-name="Grupo 120"
        fill="none"
        stroke="#e6ae65"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      >
        <path data-name="Linha 27" d="M394.876 223.227v12.22" />
        <path data-name="Linha 28" d="M400.986 229.337h-12.22" />
      </g>
      <g data-name="Grupo 121">
        <path data-name="Retângulo 42" fill="#fec775" d="M400.986 144.286h71.853v42.392h-71.853z" />
        <path
          data-name="Caminho 75"
          d="M472.839 186.678l-35.927-27.493-35.927 27.492z"
          fill="#f0ae4a"
        />
        <path
          data-name="Caminho 76"
          d="M400.987 144.286l32.474 24.851a5.681 5.681 0 0 0 6.905 0l32.474-24.851z"
          fill="#ebb66b"
        />
      </g>
      <rect
        data-name="Retângulo 43"
        width="67.931"
        height="9.165"
        rx="2.125"
        transform="translate(279.201 124.959)"
        fill="#f6debd"
      />
      <path
        data-name="Caminho 77"
        d="M325 155.359a9.911 9.911 0 1 0 9.912 9.91 9.912 9.912 0 0 0-9.912-9.91z"
        fill="#e6ae65"
      />
      <path
        data-name="Caminho 78"
        d="M325 165.269a2.169 2.169 0 0 1-2.171-2.169V79.292A2.171 2.171 0 0 1 325 77.121a2.171 2.171 0 0 1 2.171 2.171v83.81a2.169 2.169 0 0 1-2.171 2.167z"
        fill="#ffac24"
      />
      <path
        data-name="Retângulo 44"
        d="M299.218 82.693h23.612v15.815h-23.612a1.417 1.417 0 0 1-1.417-1.415V84.11a1.417 1.417 0 0 1 1.417-1.417z"
        fill="#ebb66b"
      />
      <path
        data-name="Caminho 79"
        d="M164.223 188.549v-.183a4.75 4.75 0 0 0-4.751-4.751H101.73a4.75 4.75 0 0 0-4.751 4.751v.186a4.75 4.75 0 0 0 4.751 4.749h8.3a4.748 4.748 0 0 1 4.749 4.749 4.749 4.749 0 0 1-4.749 4.751H87.715a4.749 4.749 0 0 0-4.749 4.749v.186a4.75 4.75 0 0 0 4.749 4.751h54.39a4.75 4.75 0 0 0 4.751-4.751v-.186a4.75 4.75 0 0 0-4.751-4.749h-3.043a4.75 4.75 0 0 1-4.751-4.751 4.75 4.75 0 0 1 4.751-4.749h20.409a4.75 4.75 0 0 0 4.752-4.752z"
        fill="#e8b674"
      />
      <path
        data-name="Caminho 80"
        d="M609.953 150.166a3.146 3.146 0 0 1 3.145-3.147h2.86a4.352 4.352 0 0 0 4.352-4.352 4.352 4.352 0 0 0-4.352-4.352h-21.063a3.068 3.068 0 0 1-3.069-3.069 3.069 3.069 0 0 1 3.069-3.071h9.565a4.352 4.352 0 0 0 4.352-4.352 4.352 4.352 0 0 0-4.352-4.352h-1.479a4.267 4.267 0 0 1-4.267-4.267 4.269 4.269 0 0 1 4.267-4.269h18.256a4.352 4.352 0 0 0 4.352-4.352 4.352 4.352 0 0 0-4.352-4.352h-45.05a4.352 4.352 0 0 0-4.352 4.352 4.352 4.352 0 0 0 4.352 4.352h.7a4.269 4.269 0 0 1 4.267 4.269 4.267 4.267 0 0 1-4.267 4.267h-19.966a4.352 4.352 0 0 0-4.352 4.352 4.352 4.352 0 0 0 4.352 4.352h14.951a3.071 3.071 0 0 1 3.071 3.071 3.069 3.069 0 0 1-3.071 3.069h-5.935a4.352 4.352 0 0 0-4.352 4.352 4.352 4.352 0 0 0 4.352 4.352h17.256a3.147 3.147 0 0 1 3.147 3.147 3.147 3.147 0 0 1-3.147 3.147h-12.289a4.352 4.352 0 0 0-4.352 4.352 4.352 4.352 0 0 0 4.352 4.352h62.766a4.352 4.352 0 0 0 4.352-4.352 4.352 4.352 0 0 0-4.352-4.352h-20.572a3.145 3.145 0 0 1-3.145-3.147z"
        fill="#e8b674"
      />
      <g
        data-name="Grupo 122"
        fill="none"
        stroke="#f2bc6b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      >
        <path data-name="Linha 29" d="M485.778 152.61h32.707" />
        <path data-name="Linha 30" d="M534.659 152.61h-5.391" />
        <path data-name="Linha 31" d="M485.778 165.482h39.441" />
        <path data-name="Linha 32" d="M544.723 165.482h-6.501" />
        <path data-name="Linha 33" d="M485.778 178.354h32.707" />
        <path data-name="Linha 34" d="M534.659 178.354h-5.391" />
      </g>
      <g data-name="Grupo 125">
        <g data-name="Grupo 123" fill="#fdffff">
          <path
            data-name="Caminho 81"
            d="M187.452 97.26a2.2 2.2 0 0 0-2.2-2.2h-21.867v19.971h11.816a2.2 2.2 0 0 0 2.2-2.2 2.2 2.2 0 0 0-2.2-2.2h-3.165a1.691 1.691 0 0 1-1.69-1.69 1.692 1.692 0 0 1 1.69-1.692h8.377a2.2 2.2 0 0 0 2.2-2.2 2.2 2.2 0 0 0-2.2-2.2h-2.562a1.69 1.69 0 0 1-1.69-1.69 1.69 1.69 0 0 1 1.69-1.69h7.4a2.2 2.2 0 0 0 2.201-2.209z"
          />
          <path
            data-name="Caminho 82"
            d="M71.763 97.26a2.2 2.2 0 0 1 2.2-2.2h21.865v19.971H84.012a2.2 2.2 0 0 1-2.2-2.2 2.2 2.2 0 0 1 2.2-2.2h3.165a1.69 1.69 0 0 0 1.69-1.69 1.69 1.69 0 0 0-1.69-1.692H78.8a2.2 2.2 0 0 1-2.2-2.2 2.2 2.2 0 0 1 2.2-2.2h2.56a1.689 1.689 0 0 0 1.69-1.69 1.689 1.689 0 0 0-1.69-1.69h-7.4a2.2 2.2 0 0 1-2.197-2.209z"
          />
        </g>
        <g data-name="Grupo 124">
          <path data-name="Retângulo 45" fill="#fec775" d="M93.681 89.787h71.853v42.392H93.681z" />
          <path
            data-name="Caminho 83"
            d="M165.533 132.178l-35.926-27.492-35.924 27.492z"
            fill="#f0ae4a"
          />
          <path
            data-name="Caminho 84"
            d="M93.683 89.787l32.474 24.849a5.681 5.681 0 0 0 6.905 0l32.474-24.849z"
            fill="#ebb66b"
          />
        </g>
      </g>
      <g data-name="Grupo 128">
        <g data-name="Grupo 126" fill="#fdffff">
          <path
            data-name="Caminho 85"
            d="M622.927 239.269a1.7 1.7 0 0 0-1.7-1.7h-16.9v15.434h9.134a1.7 1.7 0 0 0 1.7-1.7 1.7 1.7 0 0 0-1.7-1.7h-2.448a1.307 1.307 0 0 1-1.307-1.307 1.307 1.307 0 0 1 1.307-1.307h6.474a1.7 1.7 0 0 0 1.7-1.7 1.7 1.7 0 0 0-1.7-1.7h-1.978a1.307 1.307 0 0 1-1.307-1.307 1.307 1.307 0 0 1 1.307-1.307h5.717a1.7 1.7 0 0 0 1.701-1.706z"
          />
          <path
            data-name="Caminho 86"
            d="M533.507 239.269a1.7 1.7 0 0 1 1.7-1.7h16.9v15.434h-9.134a1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7h2.448a1.307 1.307 0 0 0 1.307-1.307 1.307 1.307 0 0 0-1.307-1.307h-6.474a1.7 1.7 0 0 1-1.7-1.7 1.7 1.7 0 0 1 1.7-1.7h1.98a1.305 1.305 0 0 0 1.3-1.307 1.305 1.305 0 0 0-1.3-1.307h-5.719a1.7 1.7 0 0 1-1.701-1.706z"
          />
        </g>
        <g data-name="Grupo 127">
          <path
            data-name="Retângulo 46"
            fill="#fec775"
            d="M550.448 233.493h55.539v32.767h-55.539z"
          />
          <path
            data-name="Caminho 87"
            d="M605.987 266.259l-27.77-21.251-27.77 21.251z"
            fill="#f0ae4a"
          />
          <path
            data-name="Caminho 88"
            d="M550.449 233.492l25.1 19.209a4.394 4.394 0 0 0 5.338 0l25.1-19.209z"
            fill="#ebb66b"
          />
        </g>
      </g>
      <g data-name="Grupo 131">
        <g data-name="Grupo 129" fill="#fdffff">
          <path
            data-name="Caminho 89"
            d="M551.333 68.718a1.347 1.347 0 0 0-1.347-1.347h-13.39v12.228h7.237a1.347 1.347 0 0 0 1.347-1.347 1.347 1.347 0 0 0-1.347-1.347h-1.936a1.036 1.036 0 0 1-1.036-1.036 1.035 1.035 0 0 1 1.036-1.034h5.125a1.348 1.348 0 0 0 1.349-1.347 1.348 1.348 0 0 0-1.349-1.349h-1.567a1.034 1.034 0 0 1-1.034-1.034 1.034 1.034 0 0 1 1.034-1.034h4.53a1.348 1.348 0 0 0 1.348-1.353z"
          />
          <path
            data-name="Caminho 90"
            d="M480.509 68.718a1.347 1.347 0 0 1 1.347-1.347h13.384v12.228h-7.232a1.348 1.348 0 0 1-1.349-1.347 1.348 1.348 0 0 1 1.349-1.347h1.936a1.038 1.038 0 0 0 1.036-1.036 1.036 1.036 0 0 0-1.036-1.034h-5.125a1.348 1.348 0 0 1-1.349-1.347 1.348 1.348 0 0 1 1.349-1.349h1.565a1.035 1.035 0 0 0 1.036-1.034 1.035 1.035 0 0 0-1.036-1.034h-4.528a1.348 1.348 0 0 1-1.347-1.353z"
          />
        </g>
        <g data-name="Grupo 130">
          <path data-name="Retângulo 47" fill="#fec775" d="M493.926 64.146h43.986v25.951h-43.986z" />
          <path
            data-name="Caminho 91"
            d="M537.911 90.097l-21.993-16.826-21.993 16.83z"
            fill="#f0ae4a"
          />
          <path
            data-name="Caminho 92"
            d="M493.925 64.146l19.88 15.212a3.48 3.48 0 0 0 4.227 0l19.878-15.212z"
            fill="#ebb66b"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
svg {
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 100%;
}
</style>