<template>
  <VuePerfectScrollbar
    class="email-content-scroll-area ps"
    :settings="settings"
    :style="$store.state.mobile ? `height: 100%; padding-top: calc(14vw);` : 'height: calc(100vh -  80px);'"
  >
    <transition name="fade" class="email__mails">
      <div v-if="dados">
        <div v-if="dados.serviceDesk.length !== 0" class="flex flex-col m-2 md:m-16">
          <div class="flex">
            <h2 class="cor-secundaria">Service Desk</h2>
            <feather-icon icon="ChevronDownIcon"></feather-icon>
            <span class="w-4/5 pl-10" v-if="!$store.state.mobile">
              <vs-divider color="rgba(0,0,0,0.3)" />
            </span>
          </div>
          <div
            class="card-padrao p-10 m-1 md:m-5 flex flex-col md:flex-row flex-wrap justify-around"
          >
            <div
              v-for="(dado, index) in dados.serviceDesk"
              :key="index"
              class="flex flex-col items-center p-3 border-solid border-b border-grey-light border-t-0 border-r-0 border-l-0 md:border-none"
              style="maxWidth: 300px; flex: 1 1 30%;"
            >
              <h3 class="text-xl cor-secundaria">{{dado.title}}</h3>
              <span>{{dado.period}}</span>
              <p class="text-16 cor-baixo-foco text-center">{{dado.body}}</p>
            </div>
          </div>
        </div>

        <div v-if="dados.support.length !== 0" class="flex flex-col m-2 md:m-16">
          <div class="flex">
            <h2 class="cor-secundaria">Sustentação</h2>
            <feather-icon icon="ChevronDownIcon"></feather-icon>
            <span class="w-4/5 pl-10" v-if="!$store.state.mobile">
              <vs-divider color="rgba(0,0,0,0.3)" />
            </span>
          </div>
          <div
            class="card-padrao p-10 m-1 md:m-5 flex flex-col md:flex-row flex-wrap justify-around"
          >
            <div
              v-for="(dado, index) in dados.support"
              :key="index"
              class="flex flex-col items-center p-3 border-solid border-b border-grey-light border-t-0 border-r-0 border-l-0 md:border-none"
              style="maxWidth: 300px; flex: 1 1 30%;"
            >
              <h3 class="text-xl cor-secundaria">{{dado.title}}</h3>
              <span>{{dado.period}}</span>
              <p class="text-16 cor-baixo-foco text-center">{{dado.body}}</p>
            </div>
          </div>
        </div>
      </div>
      <loading :height="500" :width="500" class="self-center" v-else />
    </transition>
    <vs-popup class="holamundo" :title="popup.title" :active.sync="popupActivo">
      <p>{{popup.message}}</p>
    </vs-popup>
  </VuePerfectScrollbar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  name: "resumoDados",
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      popupActivo: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
        wheelPropagation: true,
        handlers: ["click-rail", "drag-thumb", "keyboard", "wheel", "touch"]
      }
    };
  },
  computed: {
    toggleMenu: {
      get() {
        return this.$store.state.openMenuMobile;
      },
      set(value) {
        this.$store.commit("ACTIVE_MENU", value);
      }
    },
    popupVisto: {
      get() {
        return this.$store.state.popupVisto;
      },
      set(value) {
        this.$store.commit("HANDLE", { key: "popupVisto", value });
      }
    },
    dados() {
      return this.$store.state.resumoDados;
    },
    popup() {
      return this.$store.state.info.popup;
    }
  },
  methods: {
    openPopup() {
      if (this.popup.id) {
        if (!this.popupVisto) {
          this.popupVisto = true;
          this.popupActivo = true;
        }
      }
    },
    toggleM(val) {
      switch (val) {
        case true:
          this.toggleMenu = true;
          break;
        case false:
          this.toggleMenu = false;
          break;
      }
    },
    buscaDados() {
      this.$store.dispatch("getResumoDados");
    }
  },
  mounted() {
    this.buscaDados();
    this.openPopup();
  }
};
</script>
<style lang="scss" scoped>
.ps__rail-y {
  display: none !important;
}
</style>