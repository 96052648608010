<template>
  <div class="nav z-50 top-0 shadow">
    <div class="navegacao">
      <img alt="DFS logo" src="../../assets/logo2.png" />
      <ul class="lista">
        <li class="item">
          <a
            @click="(route('/resumodados')) "
            :class="$route.path == '/resumodados' ? 'active' : '' "
            v-if="true"
          >Resumo de Dados</a>
        </li>
        <li class="item">
          <a
            @click="(route('/servicos/servicedesk')) "
            :class="$route.path == '/servicos/servicedesk' ? 'active' : '' "
          >Service Desk</a>
        </li>
        <li class="item">
          <a
            @click="(route('/servicos/sustentacao')) "
            :class="$route.path == '/servicos/sustentacao' ? 'active' : '' "
          >Sustentação</a>
        </li>
      </ul>
    </div>
    <div class="perfil">
      <!-- notificação -->
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-if="true">
        <feather-icon
          icon="BellIcon"
          class="cursor-pointer mt-1 sm:mr-6 mr-2"
          :badge="notification.length"
        ></feather-icon>
        <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
          <div class="notification-top text-center p-5 background-primary text-white">
            <h3 class="text-white">{{notification.length}} Novas</h3>
            <p class="opacity-75">Notificações</p>
          </div>

          <VuePerfectScrollbar
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0 mb-10"
            :settings="settings"
          >
            <ul class="bordered-items">
              <li
                v-for="(notification, index) in notification"
                :key="index"
                class="flex justify-between px-4 py-4 notification cursor-pointer"
              >
                <div class="flex items-start">
                  <feather-icon
                    icon="InboxIcon"
                    :svgClasses="[`texto_primario`, 'stroke-current mr-1 h-6 w-6']"
                  ></feather-icon>
                  <div class="mx-2">
                    <span
                      class="font-medium block notification-title"
                      :class="[`texto_primario`]"
                    >{{notification.title}}</span>
                    <small>{{ notification.message }}</small>
                  </div>
                </div>
                <!-- <small class="mt-1 whitespace-no-wrap">10</small> para quando tiver data -->
              </li>
            </ul>
          </VuePerfectScrollbar>
          <div
            class="checkout-footer fixed bottom-0 rounded-b-lg texto_primario w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light"
          >
            <span>Isso é tudo!</span>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!-- perfil -->
      <vs-dropdown vs-custom-content class="perfil">
        <vs-avatar :src="fotoBase" />
        <div class="dados">
          <span class="text-18 nome">{{user.name +" "+ user.lastname}}</span>
          <span class="text-16">{{user.company}}</span>
        </div>
        <vs-dropdown-menu class="hover-list" style="padding: 20px">
          <li v-if="true" class="flex py-2 px-4 cursor-pointer">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" @click="$router.push('/perfil')">Profile</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" @click="sairDoApp">Sair</span>
          </li>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    VuePerfectScrollbar: VuePerfectScrollbar
  },
  data: () => {
    return {
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    access() {
      return this.$store.state.info.user.access;
    },
    user() {
      return this.$store.state.info.user;
    },
    fotoBase() {
      return this.$store.state.info.user.photo;
    },
    notification() {
      return this.$store.state.info.notification;
    },
    nameRouter() {
      if (this.$route.params.nome) {
        return this.$route.params.nome.split("-").join(" ");
      } else {
        return this.$route.name;
      }
    }
  },
  methods: {
    sairDoApp() {
      this.$store.dispatch("sairAplicacao");
    },
    alert() {
      this.$vs.dialog({
        type: "alert",
        color: "warning",
        title: "Acesso não disponível",
        text:
          "O seu contrato atual com a DFS Sistemas não contempla o segmento sustentação. Entre em contato para saber mais.",
        acceptText: "Fechar"
      });
    },
    route(rota) {
      switch (rota) {
        case "/resumodados":
          this.$router.push({ path: `${rota}` });
          this.namebar = "resumodados";
          break;
        case "/servicos/servicedesk":
          if (this.access.serviceDesk) {
            this.$router.push({ path: `${rota}` });
            this.namebar = "servicedesk";
          } else {
            this.alert();
          }
          break;
        case "/servicos/sustentacao":
          this.alert();
          //solicitado o block de sustentação
          // if (this.access.sustentacao) {
          //   this.$router.push({ path: `${rota}` });
          //   this.namebar = "sustentacao";
          // } else {
          //   this.alert();
          // }
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.notifica {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  border-bottom: #f0f1f2 1px solid;
}
.titulo {
  color: $CorSecundaria;
}
.nome {
  color: $CorSecundaria;
}
.nav {
  background-color: #fff;
  position: fixed;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 80px;
  img {
    width: 100px;
    padding: 0.8rem;
  }
  .navegacao {
    display: flex;
    align-items: center;
    .lista {
      display: flex;
      margin: 0 1rem 0 1rem;
      .item {
        padding: 10px;
        list-style: none;
        transition: all 0.3s;
        a {
          cursor: pointer;
          color: #707070;
          &.active {
            color: $CorPrimaria;
            padding-bottom: 28px;
            border-bottom: 3px solid $CorPrimaria;
          }
        }
      }
    }
  }
  .perfil {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .dados {
      display: flex;
      flex-direction: column;
      text-align: left;
    }
  }
}
</style>