import Vue from "vue";
import store from "./store";
// import router from "./router";

class controleInicialApp {
  constructor() {
    this._vm = Vue.prototype;
  }
  desligar = function(config) {
    document.getElementById("loading-bg").style.display = "block";
    if (config) {
      if (config.msg) {
        document.getElementById("text-loading").innerText = config.msg;
      }
      if (config.tipo == "fechar") {
        store.state.loadingAppConnection = false;
      }
    }
    console.log("inLoading");
  };
  ligar = function(config) {
    document.getElementById("loading-bg").style.display = "none";
    window.abriu = "abriu";
    try {
      window.ReactNativeWebView.postMessage(JSON.stringify({ disable: true }));
      // window.ReactNativeWebView.postMessage(JSON.stringify({ log: "foi" }));
    } catch (error) {
      console.log("oi");
    }
    if (config) {
      if (config.msg) {
        document.getElementById("text-loading").innerText = config.msg;
      }
      if (config.tipo == "ligar") {
        store.state.loadingAppConnection = true;
      }
    }
    console.log("offLoading");
  };
}

const initApp = new controleInicialApp();

if (initApp) {
  window.addEventListener("offline", function() {
    initApp.desligar({
      tipo: "fechar",
      msg: "Oops parece que você nao esta conectado a internet. :( ",
    });
  });
  window.addEventListener("online", function() {
    initApp.ligar({ tipo: "ligar" });
  });
}

// store.state.loadingAppConnection = false
export default function install(Vue) {
  Object.defineProperty(Vue.prototype, "$initApp", {
    get() {
      return initApp;
    },
  });
}
