<template>
  <div ref="nav" class="nav justify-between">
    <feather-icon
      v-if="$store.state.editPerfilBtn"
      icon="XIcon"
      svgClasses="cor-primaria"
      class="cursor-pointer mt-1 sm:mr-6 mx-4 flex justify-center items-center"
      @click="toggleBtnPerfil = false"
    ></feather-icon>
    <div v-else class="flex justify-center items-center">
      <feather-icon
        v-if="$route.meta.voltarIcon"
        icon="ChevronLeftIcon"
        svgClasses="cor-primaria"
        class="cursor-pointer mt-1 sm:mr-6 mx-4 flex justify-center items-center"
        @click="$router.replace($store.state.fromPageMobileChamado)"
      ></feather-icon>
      <feather-icon
        v-else-if="!$route.meta.voltarIcon"
        icon="MenuIcon"
        class="cursor-pointer mt-1 sm:mr-6 mx-4 flex justify-center items-center"
        @click="
          () => {
            toggleMenu = !toggleMenu;
            activePesquisa = false;
          }
        "
      ></feather-icon>
    </div>

    <div
      class="items-center absolute self-center justify-center w-full flex z-n-1"
    >
      <input
        key="inpy"
        ref="input"
        v-show="activePesquisa"
        class="pesquisa"
        type="text"
        placeholder=" "
        @blur="pesquisaClick"
        @keyup.enter="InputEntrada"
        v-model.trim="pesquisa"
      />
      <div v-show="!activePesquisa" key="name">
        <span v-if="nameRouter !== 'MobileChamado'">{{ nameRouter }}</span>
      </div>
    </div>

    <div class="flex justify-center items-center mx-4">
      <label
        v-if="btnPesquisa"
        class="sarch mr-2"
        :class="activePesquisa == true ? 'active' : ''"
        @click="InputEntrada"
      >
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 20">
          <path
            d="M16.76 15.6l-3.643-3.64a7.365 7.365 0 1 0-1.157 1.157l3.64 3.643a.82.82 0 1 0 1.16-1.16zm-9.4-2.512a5.727 5.727 0 1 1 5.727-5.727 5.727 5.727 0 0 1-5.723 5.73z"
            fill="#727c8e"
          />
        </svg>
      </label>

      <vs-dropdown
        vs-custom-content
        vs-trigger-click
        class="cursor-pointer"
        v-if="true"
        style="transition: all 0.3s ease-in-out;"
      >
        <feather-icon
          icon="BellIcon"
          class="cursor-pointer mt-1 sm:mr-6 mr-2"
          :badge="notification.length"
        ></feather-icon>
        <vs-dropdown-menu
          class="notification-dropdown dropdown-custom vx-navbar-dropdown"
        >
          <div
            class="notification-top text-center p-5 background-primary text-white"
          >
            <h3 class="text-white">{{ notification.length }} Novas</h3>
            <p class="opacity-75">Notificações</p>
          </div>

          <VuePerfectScrollbar
            ref="mainSidebarPs"
            class="scroll-area--nofications-dropdown p-0 mb-10"
            :settings="settings"
          >
            <ul class="bordered-items">
              <li
                v-for="(notification, index) in notification"
                :key="index"
                class="flex justify-between px-4 py-4 notification cursor-pointer"
              >
                <div class="flex items-start">
                  <feather-icon
                    icon="InboxIcon"
                    :svgClasses="[
                      `texto_primario`,
                      'stroke-current mr-1 h-6 w-6',
                    ]"
                  ></feather-icon>
                  <div class="mx-2">
                    <span
                      class="font-medium block notification-title"
                      :class="[`texto_primario`]"
                      >{{ notification.title }}</span
                    >
                    <small>{{ notification.message }}</small>
                  </div>
                </div>
                <!-- <small class="mt-1 whitespace-no-wrap">10</small> para quando tiver data -->
              </li>
            </ul>
          </VuePerfectScrollbar>
          <div
            class="checkout-footer fixed bottom-0 rounded-b-lg texto_primario w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light"
          >
            <span>Isso é tudo!</span>
          </div>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
    <!-- sidebar -->
    <div
      :class="$store.state.openMenuMobile ? 'active' : ' '"
      class="navegacao"
      :style="`height: calc(100vh - ${$store.state.chromeNavbarHeight}px); `"
      v-hammer:swipe.left="(event) => $store.commit('ACTIVE_MENU', false)"
    >
      <div class="right-0 m-2 top-0 absolute">
        <feather-icon
          icon="XIcon"
          @click="$store.commit('ACTIVE_MENU', false)"
        ></feather-icon>
      </div>

      <div class="flex flex-col items-center mb-6 w-full">
        <div class="flex flex-row w-full content-center items-center">
          <vs-avatar size="60px" :src="fotoBase" />
          <div class="flex flex-col">
            <span class="text-16 font-bold cor-secundaria">{{
              user.name + " " + user.lastname
            }}</span>
            <span class="text-14">{{ user.company }}</span>
          </div>
        </div>
        <button
          class="btn btn-outline w-11/12 mt-2"
          type="button"
          @click="route('/perfil')"
        >
          Perfil
        </button>
      </div>

      <div>
        <div>
          <span>Serviços</span>
        </div>
        <ul class="flex flex-col">
          <li class="item">
            <a
              @click="route('/resumodados')"
              :class="$route.path == '/resumodados' ? 'active' : ''"
              >Resumo de Dados</a
            >
          </li>
          <li class="item" v-if="access.serviceDesk">
            <a
              @click="route('/servicos/servicedesk')"
              :class="$route.path == '/servicos/servicedesk' ? 'active' : ''"
              >Service Desk</a
            >
          </li>
          <li class="item" v-if="access.serviceDesk">
            <a
              @click="route('/servicos/sustentacao')"
              :class="$route.path == '/servicos/sustentacao' ? 'active' : ''"
              >Sustentação</a
            >
          </li>
        </ul>
      </div>
      <filtro v-if="habilitaFiltro" />
      <div class="flex justify-center flex-auto">
        <span
          class="self-end text-18 cor-primaria"
          @click="$store.dispatch('sairAplicacao')"
          >Sair</span
        >
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="toggleMenu"
        class="bg-opacyt"
        @click="toggleMenu = !toggleMenu"
      ></div>
      <div
        v-if="activePesquisa"
        class="bg-opacyt"
        :style="`margin-top: 14vw; `"
        @click="activePesquisa = false"
      ></div>
    </transition>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import filtro from "../ListaFiltro";
export default {
  components: {
    filtro: filtro,
    VuePerfectScrollbar,
  },
  data() {
    // const self = this;
    return {
      fromPage: "",
      namebar: "resumodados",
      activenav: true,
      pesquisa: "",
      activePesquisa: false,
      flag: true,
      insetsTop: 0,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    };
  },
  computed: {
    toggleBtnPerfil: {
      get() {
        return this.$store.state.editPerfilBtn;
      },
      set(val) {
        this.$store.commit("BTN_EDIT_PERFIL_SET", val);
      },
    },
    toggleMenu: {
      get() {
        return this.$store.state.openMenuMobile;
      },
      set(value) {
        this.$store.commit("ACTIVE_MENU", value);
      },
    },
    user() {
      return this.$store.state.info.user;
    },
    // routeFrom() {
    //   return this.$router.;
    // },
    habilitaFiltro() {
      return this.$route.meta.filtro;
    },
    btnPesquisa() {
      return this.$route.meta.pesquisa;
    },
    notification() {
      return this.$store.state.info.notification;
    },
    fotoBase() {
      return this.$store.state.info.user.photo;
    },
    access() {
      return this.$store.state.info.user.access;
    },
    nameRouter() {
      if (this.$route.params.nome) {
        // return this.$route.params.nome.split("-").join(" ");
        return "Service Desk";
      } else {
        return this.$route.name;
      }
    },
  },
  methods: {
    alert() {
      this.$vs.dialog({
        type: "alert",
        color: "warning",
        title: "Acesso não disponível",
        text:
          "O seu contrato atual com a DFS Sistemas não contempla o segmento sustentação. Entre em contato para saber mais.",
        acceptText: "Fechar",
      });
    },
    route(rota) {
      switch (rota) {
        case "/resumodados":
          this.$router.push({ path: `${rota}` });
          this.toggleMenu = !this.toggleMenu;
          break;
        case "/servicos/servicedesk":
          if (this.access.serviceDesk) {
            this.$router.push({ path: `${rota}` });
            this.toggleMenu = !this.toggleMenu;
          } else {
            this.alert();
            this.toggleMenu = !this.toggleMenu;
          }
          break;
        case "/servicos/sustentacao":
          this.alert();
          this.toggleMenu = !this.toggleMenu;
          //solicitado o block de sustentação
          // if (this.access.sustentacao) {
          //   this.$router.push({ path: `${rota}` });
          //   this.toggleMenu = !this.toggleMenu;
          // } else {
          //   this.alert();
          //   this.toggleMenu = !this.toggleMenu;
          // }
          break;
        case "/perfil":
          this.$router.push({ path: `${rota}` });
          this.toggleMenu = !this.toggleMenu;

          break;
      }
    },
    pesquisaClick() {
      if (!this.pesquisa) {
        this.activePesquisa = false;
      }
    },
    InputEntrada() {
      if (!this.activePesquisa) {
        this.activePesquisa = true;
        this.$nextTick(() => this.$refs.input.focus());
      } else {
        this.activePesquisa = false;
        this.$store.commit("HANDLE", {
          value: this.pesquisa,
          key: "atualFiltroDeBusca",
        });
        this.$store.commit("HANDLE", {
          value: "search",
          key: "parametroDePesquisa",
        });
        this.$store.dispatch("getChamados", {
          search: this.pesquisa,
        });
      }
    },
  },
  mounted() {},
  beforeRouteUpdate(to, from, next) {
    this.fromPage = from;
    next();
  },
};
</script>

<style lang="scss" scoped>
.sarch {
  cursor: pointer;
  width: 20px;
  height: 20px;
  right: 4rem;
  transition: all 0.3s ease-in-out;
  &.active {
    transform: translateX(-65vw);
  }
}

.bg-opacyt {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(000, 000, 000, 0.6);
}
.pesquisa {
  border: none;
  font-size: 1rem;
}

.navegacao {
  display: flex;
  width: 70vw;
  left: 0;
  z-index: 10000;
  position: absolute;
  flex-direction: column;
  background-color: #fff;
  pointer-events: auto;
  padding: 1.4rem;
  box-shadow: 0px 5px 10px rgba(000, 000, 000, 0.16);
  transition: all 0.5s cubic-bezier(0.25, 0.1, 0, 1.08) 0s;
  transform: translateX(-320px);
  &.active {
    transform: translateX(0px);
  }
  .item {
    padding: 10px;
    list-style: none;
    transition: all 0.3s;
    a {
      color: #707070;
      &.active {
        color: $CorPrimaria;
      }
    }
  }
}
.nav {
  background-color: #fff;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 14vw;
  width: 100vw;
  box-shadow: 0px 5px 10px rgba(000, 000, 000, 0.16);
  position: fixed;
  z-index: 30000;
  transition: all 0.3s;
  img {
    width: 100px;
    padding: 0.8rem;
  }
}
</style>
