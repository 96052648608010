/* eslint-disable no-console */

import { register } from 'register-service-worker'
import ds from './main'


if (process.env.NODE_ENV === 'production') {
  var refreshing;
  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('controllerchange',
      function () {
        if (refreshing) return;
        refreshing = true;
        window.location.reload();
      }
    );
  }

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.warn(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )


    },
    registered() {
      console.warn('Service worker has been registered.')
    },
    cached() {
      console.warn('Content has been cached for offline use.')
    },
    updatefound() {
      console.warn('New content is downloading.')
      ds.$vs.notify({
        title: "Atualização disponível",
        text: 'Baixando novo conteúdo. ',
        position: "top-right",
        time: 6000,
        color: "#28C76F"
      });
    },
    updated(req) {
      console.warn('New content is available; please refresh.')
      console.warn(req)
      ds.$vs.notify({
        title: "Atualização pendente",
        text: 'Novo conteúdo disponível; Clique aqui.',
        position: "top-right",
        click: () => {
          req.waiting.postMessage({ action: 'skipWaiting' });
        },
        time: 6000,
        color: "#28C76F"
      });
    },
    offline() {
      console.warn('No internet connection found. App is running in offline mode.')
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
