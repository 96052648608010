<template>
  <div class="m-4">
    <div class="flex flex-wrap my-4">
      <vs-select
        class="item-atendi"
        label="Categoria"
        :danger="$v.categ.$error"
        :danger-text="categ.danger"
        autocomplete
        v-model="categ.model"
        @input="getDados({campo: 'PLATAFORMA',campoVar: 'plataforma',param: `${categ.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in categ.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Plataforma"
        :disabled="plataforma.options.length == 0"
        :danger="$v.plataforma.$error"
        :danger-text="plataforma.danger"
        autocomplete
        v-model="plataforma.model"
        @input="getDados({campo: 'ASSUNTO',campoVar: 'modulo',param: `${categ.model + plataforma.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in plataforma.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Modulo/Segmento"
        :danger="$v.modulo.$error"
        :danger-text="modulo.danger"
        autocomplete
        v-model="modulo.model"
        @input="getDados({campo: 'PROCESSO',campoVar: 'processo',param: `${categ.model + plataforma.model + modulo.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in modulo.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Processo"
        :disabled="processo.options.length == 0"
        :danger="$v.processo.$error"
        :danger-text="processo.danger"
        autocomplete
        v-model="processo.model"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in processo.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Prioridade"
        :disabled="prioridade.options.length == 0"
        :danger="$v.prioridade.$error"
        :danger-text="prioridade.danger"
        autocomplete
        v-model="prioridade.model"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in prioridade.options"
        />
      </vs-select>

      <vs-input
        class="item-atendi"
        label="Observador"
        placeholder="Email"
        size="default"
        v-model="observador.model"
        :danger="$v.observador.$error"
        :danger-text="observador.danger"
        :success="!$v.observador.$error && !$v.observador.$invalid"
        :success-text="observador.success"
      />

      <vs-input
        class="item-atendi"
        label="Título"
        placeholder="Título"
        size="default"
        v-model="titulo.model"
        :danger="$v.titulo.$error"
        :danger-text="titulo.danger"
        :success="!$v.titulo.$error && !$v.titulo.$invalid"
        :success-text="titulo.success"
      />

      <vs-input
        class="item-atendi"
        label="Tag"
        placeholder="Tag"
        size="default"
        v-model="tag.model"
        :danger="$v.tag.$error"
        :danger-text="tag.danger"
        :success="!$v.tag.$error && !$v.tag.$invalid"
        :success-text="tag.success"
      />

      <vs-textarea
        class="w-full large"
        label="Detalhes do Chamado"
        placeholder="Detalhes"
        size="large"
        v-model="detalhes.model"
        :danger="$v.detalhes.$error"
        :danger-text="detalhes.danger"
        :success="!$v.detalhes.$error && !$v.detalhes.$invalid"
        :success-text="detalhes.success"
        style="margin: 5px;"
      />
    </div>
    <div class="flex justify-center aling-center">
      <vs-button
        class="rounded-full"
        color="primary"
        type="filled"
        icon-pack="feather"
        icon="icon-chevron-right"
        icon-after
        @click="enviaChamado"
      >Enviar</vs-button>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { api } from "../services";
export default {
  props: ["chamado"],
  data() {
    return {
      titulo: {
        model: this.chamado.title,
        danger: "Esse campo é obrigatório!",
        success: "está tudo certo."
      },

      detalhes: {
        model: this.chamado.description,
        danger: "Esse campo é obrigatório!",
        success: "está tudo certo."
      },

      categ: {
        model: this.chamado.idCategory,
        options: this.getDados({
          campo: "CATEG",
          campoVar: "categ",
          reset: true
        }),
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      tag: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      plataforma: {
        model: this.chamado.idPlatform,
        options: [
          { id: this.chamado.idPlatform, description: this.chamado.platform }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      modulo: {
        model: this.chamado.idSubject,
        options: [
          { id: this.chamado.idSubject, description: this.chamado.subject }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      processo: {
        model: this.chamado.idProcess,
        options: [
          { id: this.chamado.idProcess, description: this.chamado.process }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      prioridade: {
        model: this.chamado.priority,
        options: [
          { id: "3", description: "Baixa" },
          { id: "2", description: "Média" },
          { id: "1", description: "Alta" }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      observador: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      }
    };
  },

  validations: {
    detalhes: {
      model: {
        required
      }
    },
    titulo: {
      model: {
        required
      }
    },
    tag: {
      model: {
        required
      }
    },
    categ: {
      model: {
        required
      }
    },
    plataforma: {
      model: {
        required
      }
    },
    modulo: {
      model: {
        required
      }
    },
    processo: {
      model: {
        required
      }
    },
    prioridade: {
      model: {
        required
      }
    },
    observador: {
      model: {
        required
      }
    }
  },
  computed: {
    validCamps() {
      if (
        this.isValidEntidade &&
        this.isValidGrupo &&
        this.isValidSubgrupo &&
        this.isValidUsuario
      ) {
        return true;
      } else {
        return false;
      }
    },

    isValidCategoria() {
      return !this.$v.categ.$invalid;
    },
    isValidPlataforma() {
      return !this.$v.plataforma.$invalid;
    },
    isValidModulo() {
      return !this.$v.modulo.$invalid;
    },
    isValidProcesso() {
      return !this.$v.processo.$invalid;
    },
    isValidPrioridade() {
      return !this.$v.prioridade.$invalid;
    },
    isValidObservador() {
      return !this.$v.observador.$invalid;
    },
    isValidTitulo() {
      return !this.$v.titulo.$invalid;
    },
    isValidDetalhes() {
      return !this.$v.detalhes.$invalid;
    },
    isValidTag() {
      return !this.$v.tag.$invalid;
    }
  },
  methods: {
    enviaChamado() {
      if (
        this.isValidCategoria &&
        this.isValidPlataforma &&
        this.isValidModulo &&
        this.isValidProcesso &&
        this.isValidPrioridade &&
        this.isValidTitulo &&
        this.isValidDetalhes
      ) {
        this.$store.dispatch("chamadoAtt", {
          body: {
            priority: this.prioridade.model,
            IdUser: this.$store.state.info.user.id,
            title: this.titulo.model,
            idCategory: this.categ.model,
            idPlataform: this.plataforma.model,
            idSubject: this.modulo.model,
            idProcess: this.processo.model,
            idUsrObs: this.observador.model,
            tag: this.tag.model,
            msg: this.detalhes.model,
            idTicket: this.chamado.id
          }
        });
      } else {
        this.$v.detalhes.$touch();
        this.$v.processo.$touch();
        this.$v.modulo.$touch();
        this.$v.plataforma.$touch();
        this.$v.categ.$touch();
        this.$v.titulo.$touch();
        this.$v.prioridade.$touch();
      }
    },

    resetFields(fieldReq) {
      switch (fieldReq) {
        case "categ":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "plataforma":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "modulo":
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        default:
          break;
      }
    },

    getDados(obj) {
      var link = `servicedesk/consultdata?${
        obj.campo ? "field=" + obj.campo : ""
      }${obj.param ? "&search=" + obj.param : ""}`;

      if (!obj.reset) {
        this.resetFields(obj.campoVar);
      }

      this.$vs.loading({
        type: "radius",
        color: "#ff9c0b"
      });

      api
        .get(link)
        .then(r => {
          if (r.data.item.length == 0 || JSON.stringify(r.data.item) === "{}") {
            this.$vs.notify({
              title: "Sem informação. ",
              text: "Essa categoria não possui dados. ",
              position: "top-right",
              color: "rgb(255, 156, 11)"
            });
            this.$vs.loading.close();
            return;
          }

          // console.log(fieldParaPesquisa.toLowerCase());
          this[obj.campoVar].options = r.data.item;
          this.$vs.loading.close();
          return r.data.item;
        })
        .catch(r => {
          console.log({ from: "getDados", r });
          this[obj.campoVar].model = "";
          this.$vs.notify({
            title: "Erro de autenticação",
            text: "Não foi possível fazer login.",
            position: "top-right",
            color: "rgb(242, 19, 93)"
          });
          this.$vs.loading.close();
        });
    },
    mudaPage() {
      this.pageActive = false;
    }
  },
  mounted() {}
};
</script>

<style>
</style>