<template>
  <div>
    <div class="rotas filtro">
      <div class="head">
        <span class="head">Filtros</span>
      </div>
      <!-- <VuePerfectScrollbar class="scroll-filtro ps" :settings="settings"> -->
        <ul class="lista">
          <li
            class="filtros cursor-pointer"
            @click="filtroAply('')"
          >
            <a class="text-16 font-bold">LIMPAR FILTRO</a>
            <!-- <span class="ico" v-if="filtro.qtdNotifications">{{filtro.qtdNotifications}}</span> -->
          </li>
          <li
            class="filtros cursor-pointer"
            v-for="(filtro, index) in filtros"
            :key="index"
            @click="filtroAply(filtro.param)"
          >
            <a class="text-14">{{filtro.description}}</a>
            <span class="ico" v-if="filtro.qtdNotifications">{{filtro.qtdNotifications}}</span>
          </li>
        </ul>
      <!-- </VuePerfectScrollbar> -->
    </div>
  </div>
</template>

<script>
// import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  // components: { VuePerfectScrollbar },
  data() {
    return {
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    loading: {
      get() {
        return this.$store.state.loadingChamado;
      },
      set(value) {
        this.$store.commit("HANDLE", { value, key: "loadingChamado" });
      }
    },
    filtros() {
      return this.$store.state.info.filter;
    }
  },
  methods: {
    filtroAply(param) {
      this.loading = true;
      // this.$vs.loading({ type: "radius", color: "#ff9c0b" });
      this.$store.commit("HANDLE", { value: param, key: "atualFiltroDeBusca" });
      this.$store.commit("HANDLE", {
        value: "status",
        key: "parametroDePesquisa"
      });
      this.$store.dispatch("getChamados", { status: param });
    }
  }
};
</script>

<style lang="scss">
.scroll-filtro {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: 300px;
  height: 100%;
}
.head {
  color: $CorDescricao;
}
.filtro {
  padding-top: 1rem;
  .filtros {
    padding: 10px;
    list-style: none;
    transition: all 0.3s;
    display: flex;
    justify-content: space-between;
    .ico {
      background: $CorPrimaria;
      box-shadow: 0px 5px 10px $CorPrimaria;
      font-size: 13px;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      padding: 0.3rem;
      color: #fff;
      height: 18px;
      vertical-align: middle;
      display: flex;
    }
    a {
      color: #707070;
    }
  }
}
</style>