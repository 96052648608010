<template>
  <div>
    <h2 class="text-22 cor-secundaria my-5 mx-3 font-medium">Novo Chamado:</h2>

    <div v-if="pageActive" class="rounded-card flex flex-col shadow-card bg-white px-8 py-8">
      <div class>
        <vs-select
          class="w-full my-3"
          label="Entidade"
          :danger="$v.entidade.$error"
          :danger-text="entidade.danger"
          :success="!$v.entidade.$error && !$v.entidade.$invalid"
          :success-text="entidade.success"
          @input="getDados({campo:'GRUPO', param: `${entidade.model}`, campoVar:'grupo'})"
          autocomplete
          v-model="entidade.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.title"
            v-for="(item,index) in entidade.options"
          />
        </vs-select>

        <vs-select
          class="w-full my-3"
          label="Grupo"
          :disabled="grupo.options.length == 0"
          :danger="$v.grupo.$error"
          :danger-text="grupo.danger"
          :success="!$v.grupo.$error && !$v.grupo.$invalid"
          :success-text="grupo.success"
          @input="getDados({campo:'SUBGRUPO', param: `${entidade.model + grupo.model}`, campoVar:'subgrupo'})"
          autocomplete
          v-model="grupo.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in grupo.options"
          />
        </vs-select>

        <vs-select
          class="w-full my-3"
          label="Sub-Grupo"
          @input="getUser({entidade:entidade.model, grupo: grupo.model, sbgrupo: subgrupo.model})"
          :disabled="subgrupo.options.length == 0"
          :danger="$v.subgrupo.$error"
          :danger-text="subgrupo.danger"
          :success="!$v.subgrupo.$error && !$v.subgrupo.$invalid"
          :success-text="subgrupo.success"
          autocomplete
          v-model="subgrupo.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in subgrupo.options"
          />
        </vs-select>

        <vs-select
          v-if="isAtendente"
          class="w-full my-3"
          label="Usuário"
          :disabled="usuario.options.length == 0"
          :danger="$v.usuario.$error"
          :danger-text="usuario.danger"
          :success="!$v.usuario.$error && !$v.usuario.$invalid"
          :success-text="usuario.success"
          autocomplete
          v-model="usuario.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in usuario.options"
          />
        </vs-select>
      </div>

      <div class="flex justify-center aling-center mt-8">
        <vs-button
          :disabled="!validCamps"
          class="rounded-full"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-chevron-right"
          icon-after
          @click="mudaPage()"
        >Continuar</vs-button>
      </div>
    </div>

    <div v-else class="rounded-card flex flex-col shadow-card bg-white px-8 py-8">
      <VuePerfectScrollbar
        class="email-content-scroll-area"
        :settings="settings"
        style="min-height: 70px;"
      >
        <div class="flex justify-between relative" style="min-width: 500px; ">
          <div class="m-2">
            <h3 class="text-18 font-light cor-baixo-foco">Cliente:</h3>
            <p class="text-18 font-bold cor-secundaria">{{dadosClient.client}}</p>
          </div>
          <div class="m-2">
            <h3 class="text-18 font-light cor-baixo-foco">Email:</h3>
            <p class="text-18 font-bold cor-secundaria">{{dadosClient.email}}</p>
          </div>
          <div class="m-2">
            <h3 class="text-18 font-light cor-baixo-foco">Telefone :</h3>
            <p class="text-18 font-bold cor-secundaria">{{dadosClient.tel}}</p>
          </div>
        </div>
      </VuePerfectScrollbar>
      <div class="flex flex-wrap my-4">
        <vs-select
          class="item-atendi"
          label="Categoria"
          :disabled="categ.options.length == 0"
          :danger="$v.categ.$error"
          :danger-text="categ.danger"
          autocomplete
          v-model="categ.model"
          @input="getDados({campo: 'PLATAFORMA',campoVar: 'plataforma',param: `${categ.model}`,})"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in categ.options"
          />
        </vs-select>

        <vs-select
          class="item-atendi"
          label="Plataforma"
          :disabled="plataforma.options.length == 0"
          :danger="$v.plataforma.$error"
          :danger-text="plataforma.danger"
          autocomplete
          v-model="plataforma.model"
          @input="getDados({campo: 'ASSUNTO',campoVar: 'modulo',param: `${categ.model + plataforma.model}`,})"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in plataforma.options"
          />
        </vs-select>

        <vs-select
          class="item-atendi"
          label="Modulo/Segmento"
          :disabled="modulo.options.length == 0"
          :danger="$v.modulo.$error"
          :danger-text="modulo.danger"
          autocomplete
          v-model="modulo.model"
          @input="getDados({campo: 'PROCESSO',campoVar: 'processo',param: `${categ.model + plataforma.model + modulo.model}`,})"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in modulo.options"
          />
        </vs-select>

        <vs-select
          class="item-atendi"
          label="Processo"
          :disabled="processo.options.length == 0"
          :danger="$v.processo.$error"
          :danger-text="processo.danger"
          autocomplete
          v-model="processo.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in processo.options"
          />
        </vs-select>

        <vs-select
          class="item-atendi"
          label="Prioridade"
          :disabled="prioridade.options.length == 0"
          :danger="$v.prioridade.$error"
          :danger-text="prioridade.danger"
          autocomplete
          v-model="prioridade.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in prioridade.options"
          />
        </vs-select>

        <vs-input
          class="item-atendi"
          label="Observador"
          placeholder="Email"
          size="default"
          v-model="observador.model"
          :danger="$v.observador.$error"
          :danger-text="observador.danger"
          :success="!$v.observador.$error && !$v.observador.$invalid"
          :success-text="observador.success"
        />

        <vs-input
          class="item-atendi"
          label="Título"
          placeholder="Título"
          size="default"
          v-model="titulo.model"
          :danger="$v.titulo.$error"
          :danger-text="titulo.danger"
          :success="!$v.titulo.$error && !$v.titulo.$invalid"
          :success-text="titulo.success"
        />

        <vs-textarea
          class="w-full large"
          label="Detalhes do Chamado"
          placeholder="Detalhes"
          size="large"
          v-model="detalhes.model"
          :danger="$v.detalhes.$error"
          :danger-text="detalhes.danger"
          :success="!$v.detalhes.$error && !$v.detalhes.$invalid"
          :success-text="detalhes.success"
          style="margin: 5px;"
        />
      </div>
      <div class="flex justify-center aling-center">
        <vs-button
          class="rounded-full"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-chevron-right"
          icon-after
          @click="enviaChamado"
        >Enviar</vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { api } from "../services";
export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      pageActive: true,
      dadosClientAdm: {},
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

      titulo: {
        model: "",
        danger: "Esse campo é obrigatório!",
        success: "está tudo certo."
      },

      detalhes: {
        model: "",
        danger: "Esse campo é obrigatório!",
        success: "está tudo certo."
      },

      usuario: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      subgrupo: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      grupo: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      entidade: {
        model: "",
        options: this.$store.state.info.entity,
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      categ: {
        model: "",
        options: this.getDados({
          campo: "CATEG",
          campoVar: "categ",
          reset: true
        }),
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      plataforma: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      modulo: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      processo: {
        model: "",
        options: [],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      prioridade: {
        model: "",
        options: [
          { id: "1", description: "Baixa" },
          { id: "2", description: "Média" },
          { id: "3", description: "Alta" }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      observador: {
        model: "",
        danger: "Esse campo é obrigatório! E deve ser um email.",
        success: "está tudo certo."
      }
    };
  },

  validations: {
    detalhes: {
      model: {
        required
      }
    },
    titulo: {
      model: {
        required
      }
    },
    entidade: {
      model: {
        required
      }
    },
    grupo: {
      model: {
        required
      }
    },
    subgrupo: {
      model: {
        required
      }
    },
    usuario: {
      model: {
        required
      }
    },
    categ: {
      model: {
        required
      }
    },
    plataforma: {
      model: {
        required
      }
    },
    modulo: {
      model: {
        required
      }
    },
    processo: {
      model: {
        required
      }
    },
    prioridade: {
      model: {
        required
      }
    },
    observador: {
      model: {
        required,
        email
      }
    }
  },
  computed: {
    isAtendente() {
      if (
        this.$store.state.info.user.type == "ANALISTA" ||
        this.$store.state.info.user.type == "GESTOR SERVICE DESK"
      ) {
        return true;
      } else {
        return false;
      }
    },
    validCamps() {
      if (
        this.isValidEntidade &&
        this.isValidGrupo &&
        this.isValidSubgrupo &&
        this.isValidUsuario
      ) {
        return true;
      } else {
        return false;
      }
    },
    dadosClient() {
      if (this.isAtendente) {
        return {
          client: this.dadosClientAdm[this.usuario.model].name,
          email: this.dadosClientAdm[this.usuario.model].email,
          tel: this.dadosClientAdm[this.usuario.model].telephone
        };
      } else {
        this.usuario.model = this.$store.state.info.user.id;
        return {
          client:
            this.$store.state.info.user.name +
            " " +
            this.$store.state.info.user.lastname,
          email: this.$store.state.info.user.email,
          tel: this.$store.state.info.user.telephone
        };
      }
    },
    isValidEntidade() {
      return !this.$v.entidade.$invalid;
    },
    isValidGrupo() {
      return !this.$v.grupo.$invalid;
    },
    isValidSubgrupo() {
      return !this.$v.subgrupo.$invalid;
    },
    isValidUsuario() {
      if (this.isAtendente) {
        return !this.$v.usuario.$invalid;
      } else {
        return true;
      }
    },
    isValidCategoria() {
      return !this.$v.categ.$invalid;
    },
    isValidPlataforma() {
      return !this.$v.plataforma.$invalid;
    },
    isValidModulo() {
      return !this.$v.modulo.$invalid;
    },
    isValidProcesso() {
      return !this.$v.processo.$invalid;
    },
    isValidPrioridade() {
      return !this.$v.prioridade.$invalid;
    },
    isValidObservador() {
      return !this.$v.observador.$invalid;
    },
    isValidTitulo() {
      return !this.$v.titulo.$invalid;
    },
    isValidDetalhes() {
      return !this.$v.detalhes.$invalid;
    }
  },
  methods: {
    enviaChamado() {
      if (
        this.isValidCategoria &&
        this.isValidPlataforma &&
        this.isValidModulo &&
        this.isValidProcesso &&
        this.isValidPrioridade &&
        this.isValidTitulo &&
        this.isValidDetalhes
      ) {
        this.$store.dispatch("postCreate", {
          body: {
            priority: this.prioridade.model,
            IdUser: this.usuario.model,
            title: this.titulo.model,
            idCategory: this.categ.model,
            idPlataform: this.plataforma.model,
            idSubject: this.modulo.model,
            idProcess: this.processo.model,
            idUsrObs: this.observador.model,
            tag: "",
            msg: this.detalhes.model,
            idEntity: this.entidade.model,
            idGroup: this.grupo.model,
            idSubGroup: this.subgrupo.model
          }
        });
      } else {
        this.$v.detalhes.$touch();
        this.$v.processo.$touch();
        this.$v.modulo.$touch();
        this.$v.plataforma.$touch();
        this.$v.categ.$touch();
        this.$v.titulo.$touch();
        this.$v.prioridade.$touch();
        this.$vs.notify({
          title: "Atenção!",
          text:
            "Você precisa preencher o campo detalhes do chamado corretamente.",
          position: "top-right",
          color: "rgb(242, 19, 93)"
        });
      }
    },

    resetFields(fieldReq) {
      switch (fieldReq) {
        case "entidade":
          this.grupo.options = [];
          this.grupo.model = "";
          this.subgrupo.options = [];
          this.subgrupo.model = "";
          this.usuario.options = [];
          this.usuario.model = "";
          break;
        case "grupo":
          this.subgrupo.options = [];
          this.subgrupo.model = "";
          this.usuario.options = [];
          this.usuario.model = "";
          break;
        case "subgrupo":
          this.usuario.options = [];
          this.usuario.model = "";
          break;
        case "categ":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "plataforma":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "modulo":
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        default:
          break;
      }
    },
    getUser(obj) {
      var link = `userall?${obj.entidade ? "entidade=" + obj.entidade : ""}${
        obj.grupo ? "&grupo=" + obj.grupo : ""
      }${obj.sbgrupo ? "&sbgrupo=" + obj.sbgrupo : ""}`;

      api
        .get(link)
        .then(r => {
          if (r.data.userAll.length == 0) {
            this.$vs.notify({
              title: "Sem informação. ",
              text: "Essa categoria não possui dados. ",
              position: "top-right",
              color: "rgb(255, 156, 11)"
            });
          }

          // console.log(fieldParaPesquisa.toLowerCase());
          // this[obj.campoVar].options = r.data.item;
          var users = [];
          r.data.userAll.map((user, index) => {
            var organize = {
              id: user.id,
              description: user.name + " " + user.lastname
            };
            this.dadosClientAdm[user.id] = user;
            users.push(organize);
          });
          this.usuario.options = users;
          this.$vs.loading.close();
        })
        .catch(r => {
          console.log({ from: "getUser", r });
          this.$vs.notify({
            title: "Erro de autenticação",
            text: "Não foi possível fazer login.",
            position: "top-right",
            color: "rgb(242, 19, 93)"
          });
          this.$vs.loading.close();
        });
    },

    getDados(obj) {
      var link = `servicedesk/consultdata?${
        obj.campo ? "field=" + obj.campo : ""
      }${obj.param ? "&search=" + obj.param : ""}`;

      if (!obj.reset) {
        this.resetFields(obj.campoVar);
      }

      this.$vs.loading({
        type: "radius",
        color: "#ff9c0b"
      });

      api
        .get(link)
        .then(r => {
          if (r.data.item.length == 0) {
            this.$vs.notify({
              title: "Sem informação. ",
              text: "Essa categoria não possui dados. ",
              position: "top-right",
              color: "rgb(255, 156, 11)"
            });
            this.$vs.loading.close();
            return;
          }

          // console.log(fieldParaPesquisa.toLowerCase());
          this[obj.campoVar].options = r.data.item;
          this.$vs.loading.close();
          return r.data.item;
        })
        .catch(r => {
          console.log({ from: "getDados", r });
          this[obj.campoVar].model = "";
          this.$vs.notify({
            title: "Erro de autenticação",
            text: "Não foi possível fazer login.",
            position: "top-right",
            color: "rgb(242, 19, 93)"
          });
          this.$vs.loading.close();
        });
    },
    mudaPage() {
      this.pageActive = false;
    }
  },
  mounted() {}
};
</script>

<style>
</style>