<template>
  <!-- ação distribuir -------------------------------------------------->
  <div v-if="acao == 'Distribuir'" class="flex flex-col content-center justify-center items-center">
    <vs-select
      class="form-popup"
      label="Categoria"
      @input="getDados({
        campo: 'ANALISTA',
        campoVar: 'analista',
        acao: 'distribuir',
        param: chamadoInfo.idEntity
        })"
      autocomplete
      v-model="distribuir.categoria.model"
      :danger="$v.distribuir.categoria.$error"
      :danger-text="distribuir.categoria.danger"
      :success="!$v.distribuir.categoria.$error && !$v.distribuir.categoria.$invalid"
      :success-text="distribuir.categoria.success"
      :disabled="!distribuir.categoria.options"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in distribuir.categoria.options"
      />
    </vs-select>
    <vs-select
      class="form-popup"
      label="Analista"
      autocomplete
      v-model="distribuir.analista.model"
      :danger="$v.distribuir.analista.$error"
      :danger-text="distribuir.analista.danger"
      :success="!$v.distribuir.analista.$error && !$v.distribuir.analista.$invalid"
      :success-text="distribuir.analista.success"
      :disabled="distribuir.analista.options.length == 0"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in distribuir.analista.options"
      />
    </vs-select>
    <vs-input
      class="form-popup"
      label="Interação"
      placeholder="Interação"
      size="default"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />
    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação associar ---------------------------------------------->
  <div
    v-else-if="acao == 'Associar'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-input
      class="form-popup"
      label="Número do chamado. "
      placeholder="000001"
      size="default"
      v-model="associar.model"
      :danger="$v.associar.$error"
      :danger-text="associar.danger"
      :success="!$v.associar.$error && !$v.associar.$invalid"
      :success-text="associar.success"
    />
    <div class="flex justify-between pt-5 form-popup">
      <vs-button v-if="associar.model" color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button v-else color="#707070" disable type="filled">Confirmar</vs-button>
    </div>
  </div>

  <!-- ação Atender --------------------------------------------------------->
  <div
    v-else-if="acao == 'Atender'"
    class="flex flex-col content-center justify-center items-center"
  >
    <div class="flex flex-col form-popup">
      <span class="self-start text-16 font-medium cor-secundaria mb-2">Chamado:</span>
      <span
        class="text-16 font-medium py-2 bg-grey-light rounded-full border-grey border-solid text-center"
      >{{id}}</span>
    </div>

    <vs-input
      class="form-popup mt-2"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA O ATENDIMENTO DESTE CHAMADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Encerrar --------------------------------------------------------->
  <div
    v-else-if="acao == 'Encerrar'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-select
      class="form-popup"
      label="Plataforma"
      autocomplete
      @input="getDados({
        campo: 'ASSUNTO',
        campoVar: 'segmento',
        acao: 'encerrar',
        param: `${chamadoInfo.idCategory + encerrar.plataforma.model}`,
        })"
      v-model="encerrar.plataforma.model"
      :danger="$v.encerrar.plataforma.$error"
      :danger-text="encerrar.plataforma.danger"
      :success="!$v.encerrar.plataforma.$error && !$v.encerrar.plataforma.$invalid"
      :success-text="encerrar.plataforma.success"
      :disabled="encerrar.plataforma.options.length == 0"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in encerrar.plataforma.options"
      />
    </vs-select>

    <vs-select
      class="form-popup"
      label="Módulo/Segmento"
      @input="getDados({
        campo: 'PROCESSO',
        campoVar: 'processo',
        acao: 'encerrar',
        param: `${chamadoInfo.idCategory + encerrar.plataforma.model + encerrar.segmento.model}`,
        })"
      :disabled="encerrar.segmento.options.length == 0"
      :danger="$v.encerrar.segmento.$error"
      :danger-text="encerrar.segmento.danger"
      :success="!$v.encerrar.segmento.$error && !$v.encerrar.segmento.$invalid"
      :success-text="encerrar.segmento.success"
      autocomplete
      v-model="encerrar.segmento.model"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in encerrar.segmento.options"
      />
    </vs-select>

    <vs-select
      class="form-popup"
      label="Processo"
      autocomplete
      @input="getDados({
        campo: 'ENCERRAR',
        campoVar: 'classEncerramento',
        acao: 'encerrar',
        param: `${chamadoInfo.idCategory}`,
        })"
      v-model="encerrar.processo.model"
      :danger="$v.encerrar.processo.$error"
      :danger-text="encerrar.processo.danger"
      :success="!$v.encerrar.processo.$error && !$v.encerrar.processo.$invalid"
      :success-text="encerrar.processo.success"
      :disabled="encerrar.processo.options.length == 0"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in encerrar.processo.options"
      />
    </vs-select>

    <vs-select
      class="form-popup"
      label="Classificação de Encerramento"
      autocomplete
      v-model="encerrar.classEncerramento.model"
      :danger="$v.encerrar.classEncerramento.$error"
      :danger-text="encerrar.classEncerramento.danger"
      :success="!$v.encerrar.classEncerramento.$error && !$v.encerrar.classEncerramento.$invalid"
      :success-text="encerrar.classEncerramento.success"
      :disabled="encerrar.classEncerramento.options.length == 0"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.description"
        v-for="(item,index) in encerrar.classEncerramento.options"
      />
    </vs-select>

    <vs-input
      class="form-popup"
      label="Interação"
      placeholder="Interação"
      size="default"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <vs-input
      class="form-popup"
      label="Tag"
      placeholder="Tag"
      size="default"
      v-model="encerrar.tag.model"
      :danger="$v.encerrar.tag.$error"
      :danger-text="encerrar.tag.danger"
      :success="!$v.encerrar.tag.$error && !$v.encerrar.tag.$invalid"
      :success-text="encerrar.tag.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA ENCERRAMENTO DO CHAMADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Reabrir --------------------------------------------------------->
  <div
    v-else-if="acao == 'Reabrir'"
    class="flex flex-col content-center justify-center items-center"
  >
    <div class="flex flex-col form-popup">
      <span class="self-start text-16 font-medium cor-secundaria mb-2">Chamado:</span>
      <span
        class="text-16 font-medium py-2 bg-grey-light rounded-full border-grey border-solid text-center"
      >{{id}}</span>
    </div>

    <vs-input
      class="form-popup mt-2"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA A REABERTURA DESTE CHAMADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Cancelar --------------------------------------------------------->
  <div
    v-else-if="acao == 'Cancelar'"
    class="flex flex-col content-center justify-center items-center"
  >
    <div class="flex flex-col form-popup">
      <span class="self-start text-16 font-medium cor-secundaria mb-2">Chamado:</span>
      <span
        class="text-16 font-medium py-2 bg-grey-light rounded-full border-grey border-solid text-center"
      >{{id}}</span>
    </div>

    <vs-input
      class="form-popup mt-2"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA O CANCELAMENTO DESTE CHAMADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Solucionar --------------------------------------------------------->
  <div
    v-else-if="acao == 'Solucionar'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-input
      class="form-popup"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA CHAMADO SOLUCIONADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Legendas --------------------------------------------------------->
  <div
    v-else-if="acao == 'Legendas'"
    class="flex flex-col content-center justify-center items-center"
  >
    <div class="form-popup flex p-1" v-for="(legenda, index) in legendas" :key="index">
      <span class="icone-status-chamado self-start" :style="legenda.estilo"></span>
      <span class="text-16 cor-secundaria text-center w-full">{{legenda.description}}</span>
    </div>
  </div>

  <!-- ação Reprogramar --------------------------------------------------------->
  <div
    v-else-if="acao == 'Reprogramar'"
    class="flex flex-col content-center justify-center items-center"
  >
    <div class="flex flex-col form-popup">
      <span class="self-start text-16 font-medium cor-secundaria mb-2">Chamado:</span>
      <span
        class="text-16 font-medium py-2 bg-grey-light rounded-full border-grey border-solid text-center"
      >{{id}}</span>
    </div>

    <vs-input
      class="form-popup"
      label="Data"
      placeholder=" "
      size="default"
      type="date"
      v-model="reprogramar.date.model"
      :danger="$v.reprogramar.date.$dirty && !$v.reprogramar.date.model.dateValidate"
      :danger-text="reprogramar.date.danger"
      :success="!$v.reprogramar.date.$error && !$v.reprogramar.date.$invalid"
      :success-text="reprogramar.date.success"
      @input="$v.reprogramar.date.$touch()"
    />

    <vs-input
      class="form-popup"
      label="Hora"
      placeholder=" "
      size="default"
      type="time"
      v-model="reprogramar.hour.model"
      :danger="$v.reprogramar.hour.$dirty && !$v.reprogramar.hour.model.dateValidate"
      :danger-text="reprogramar.hour.danger"
      :success="!$v.reprogramar.hour.$error && !$v.reprogramar.hour.$invalid"
      :success-text="reprogramar.hour.success"
      @input="$v.reprogramar.hour.$touch()"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA A REPROGRAMAÇÃO DESTE CHAMADO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Adic. Documentos --------------------------------------------------------->
  <div
    v-else-if="acao == 'Adic. Documentos'"
    class="flex flex-col lg:flex-row content-center justify-center items-center"
  >
    <vs-uploadd
      limit="1"
      single-upload
      text="Adic. Documentos"
      action="https://api.portal.dfssistemas.com.br/upload"
      :data="{XToken:'1234567890',token:token,idTicket:id,user:$store.state.info.user.id}"
      @on-success="(e)=> {uploadRess(e, 'success')}"
      @on-error="(e)=> {uploadRess(e, 'error')}"
      show-upload-button
    />

    <div>
      <h2 class="text-16 cor-secundaria">Extensões de arquivos aceitas:</h2>
      <p
        class="text-15 cor-baixo-foco mb-2"
      >.xlsx, .xlsm, .xls, .pdf, .png, .jpeg, .jpg, .word, .wordx, .pptx, .ppt, .txt, .csv, .ptm, .prw, .prx, .xml, .ini, .zip, .rar e .7z</p>
      <h2 class="text-16 cor-secundaria">Limites:</h2>
      <p class="text-15 cor-baixo-foco">
        Somente é permitido adicionar um documento por vez de até
        <strong>5mb</strong>, recomendamos caso necessite adicionar mais de um documento compacta-los em formato .zip.
      </p>
    </div>
  </div>

  <!-- ação Documentos --------------------------------------------------------->
  <div
    v-else-if="acao == 'Documentos'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-table
      class="w-full"
      @search="handleSearch"
      v-model="documentos.model"
      pagination
      max-items="4"
      search
      :data="documents"
    >
      <template slot="header">
        <h3>Escolha o documento.</h3>
      </template>
      <template slot="thead">
        <vs-th sort-key="Nome">Nome</vs-th>
        <vs-th sort-key="numero">Formato</vs-th>
        <vs-th sort-key="data">Data</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].Nome">{{data[indextr].title | limitCharac(20)}}</vs-td>

          <vs-td :data="data[indextr].Formato">{{data[indextr].format.toUpperCase()}}</vs-td>

          <vs-td :data="data[indextr].Data">{{data[indextr].date.split("-")[0]}}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <div class="flex justify-end pt-5 form-popup">
      <vs-button v-if="documentos.model" color="#ff9c0b" type="filled" @click="acaoBaixar">Baixar</vs-button>
      <vs-button v-else color="#707070" type="filled">Baixar</vs-button>
      <a class="hidden" id="down"></a>
    </div>
  </div>

  <!-- ação Externo --------------------------------------------------------->
  <div
    v-else-if="acao == 'Externo'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-input
      class="form-popup"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA O ENVIO PARA ATENDIMENTO EXTERNO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>

  <!-- ação Interno --------------------------------------------------------->
  <div
    v-else-if="acao == 'Interno'"
    class="flex flex-col content-center justify-center items-center"
  >
    <vs-input
      class="form-popup"
      label="Interação"
      placeholder="Interação"
      size="large"
      v-model="interacao.model"
      :danger="$v.interacao.$error"
      :danger-text="interacao.danger"
      :success="!$v.interacao.$error && !$v.interacao.$invalid"
      :success-text="interacao.success"
    />

    <div class="mx-10 flex flex-col self-center mt-10 mb-5">
      <span class="text-16 font-medium cor-secundaria">CONFIRMA O RETORNO PARA ATENDIMENTO INTERNO?</span>
    </div>

    <div class="flex justify-between pt-5 form-popup">
      <vs-button color="#ff9c0b" type="filled" @click="acaoEnviar">Confirmar</vs-button>
      <vs-button color="#707070" type="filled" @click="fecharPopup">Cancelar</vs-button>
    </div>
  </div>
</template>

<script>
import { required, numeric } from "vuelidate/lib/validators";
import { api } from "../services";
import vsUploadd from "./vsUploadd.vue";

const dateValidate = val => {
  var date = new Date(val + " " + "12:10:10");
  var atualDate = new Date();
  var isValido = date.getTime() - atualDate.getTime();
  if (isValido < 0) {
    return false;
  } else {
    return true;
  }
};
export default {
  props: ["acao", "id", "chamadoInfo"],
  components: { vsUploadd },
  data() {
    return {
      reprogramar: {
        date: {
          model: "",
          danger: "Só são válidas datas a partir de hoje!",
          success: "está tudo certo."
        },
        hour: {
          model: "",
          danger: "Esse campo é obrigatório!",
          success: "está tudo certo."
        }
      },
      distribuir: {
        categoria: {
          model: "",
          options: this.getDados({
            campo: "CATEG",
            campoVar: "categoria",
            acao: "distribuir",
            reset: true
          }),
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        },
        analista: {
          model: "",
          options: [],
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        }
      },

      associar: {
        model: null,
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      documentos: {
        model: ""
      },

      encerrar: {
        plataforma: {
          model: this.chamadoInfo.idPlatform,
          options: this.getDados({
            campo: "PLATAFORMA",
            campoVar: "plataforma",
            acao: "encerrar",
            param: `${this.chamadoInfo.idCategory}`,
            reset: true
          }),
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        },
        segmento: {
          model: this.chamadoInfo.idSubject,
          options: [
            {
              id: this.chamadoInfo.idSubject,
              description: this.chamadoInfo.subject
            }
          ],
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        },
        processo: {
          model: this.chamadoInfo.idProcess,
          options: [
            {
              id: this.chamadoInfo.idProcess,
              description: this.chamadoInfo.process
            }
          ],
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        },
        classEncerramento: {
          model: "",
          options: this.getDados({
            campo: "ENCERRAR",
            campoVar: "classEncerramento",
            acao: "encerrar",
            param: `${this.chamadoInfo.idCategory}`,
            reset: true
          }),
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        },
        tag: {
          model: "",
          danger: "Esse campo é obrigatório! ",
          success: "está tudo certo."
        }
      },

      interacao: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      }
    };
  },
  validations: {
    reprogramar: {
      date: {
        model: {
          required,
          dateValidate
        }
      },
      hour: {
        model: {
          required
        }
      }
    },
    interacao: {
      model: {
        required
      }
    },
    distribuir: {
      categoria: {
        model: {
          required
        }
      },
      analista: {
        model: {
          required
        }
      }
    },
    associar: {
      model: {
        required,
        numeric
      }
    },
    encerrar: {
      plataforma: {
        model: {
          required
        }
      },
      segmento: {
        model: {
          required
        }
      },
      processo: {
        model: {
          required
        }
      },
      classEncerramento: {
        model: {
          required
        }
      },
      tag: {
        model: {
          required
        }
      }
    }
  },
  methods: {
    acaoBaixar() {
      var a = document.getElementById("down");
      a.href = this.documentos.model.link;
      a.download = this.documentos.model.title;
      a.target = "_blank";
      a.click();
    },
    fecharPopup() {
      this.$emit("fechar", false);
    },
    handleSearch(searching) {
      // console.log(searching);
    },
    uploadRess(e, type) {
      // console.log(e);
      if (type == "error") {
        this.$vs.notify({
          title: "Mensagem",
          text: "Problemas foram encontrados, tente novamente mais tarde.",
          time: 6000,
          position: "top-right",
          color: "rgb(242, 19, 93)"
        });
      } else {
        var r = JSON.parse(e.target.response);
        switch (r.message) {
          case "invalid file":
            this.$vs.notify({
              title: "Erro ao adicionar documento:",
              text: "Arquivo não suportado.",
              time: 6000,
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
            return;
          case "file is too big":
            this.$vs.notify({
              title: "Erro ao adicionar documento:",
              text: "Documento maior que 5mb.",
              time: 6000,
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
            return;
          case "upload failed":
            this.$vs.notify({
              title: "Erro:",
              text: "Erro ao realizar upload do documento.",
              time: 6000,
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
            return;
          case "upload success":
            this.$store.dispatch("uplDoc", { dfs: r.dfs_json, id: this.id });
            return;
          default:
            break;
        }
      }
    },
    acaoEnviar() {
      switch (this.acao) {
        case "Encerrar":
          if (this.isValidEncerrar && this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/close",
                body: {
                  idTicket: this.id,
                  idPlatform: this.encerrar.plataforma.model,
                  idSegment: this.encerrar.segmento.model,
                  idProcess: this.encerrar.processo.model,
                  idOption: this.encerrar.classEncerramento.model,
                  msg: this.interacao.model,
                  tag: this.encerrar.tag.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
          } else {
            this.$v.encerrar.classEncerramento.$touch();
            this.$v.encerrar.segmento.$touch();
            this.$v.encerrar.processo.$touch();
            this.$v.encerrar.plataforma.$touch();
            this.$v.encerrar.tag.$touch();
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              time: 5000,
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Distribuir":
          if (this.isValidDistribuir) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/distribute/",
                body: {
                  idTicket: this.id,
                  idCategory: this.distribuir.categoria.model,
                  idAnalyst: this.distribuir.analista.model,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            // console.log(
            //   this.distribuir.analista.model,
            //   this.distribuir.categoria.model,
            //   this.interacao.model
            // );
          } else {
            this.$v.distribuir.analista.$touch();
            this.$v.distribuir.categoria.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              time: 5000,
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Associar":
          // console.log(this.associar.model);
          this.$store
            .dispatch("postActions", {
              endpoint: "servicedesk/connect/",
              body: {
                idTicket: this.id,
                item: [
                  {
                    idOption: this.associar.model
                  }
                ]
              }
            })
            .then(r => {
              this.$emit("fechar");
            });
          break;
        case "Atender":
          this.$store
            .dispatch("postActions", {
              endpoint: "servicedesk/answer",
              body: {
                idTicket: this.id,
                msg: this.interacao.model
              }
            })
            .then(r => {
              this.$emit("fechar");
            });

          // console.log("enviado atender" + " " + this.id);
          break;
        case "Reabrir":
          if (this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/reopen",
                body: {
                  idTicket: this.id,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            //  console.log("enviado Reabrir" + " " + this.id);
          } else {
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }

          break;
        case "Cancelar":
          if (this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/cancel",
                body: {
                  idTicket: this.id,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            //  console.log("enviado Cancelar" + " " + this.id);
          } else {
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Externo":
          if (this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/external",
                body: {
                  idTicket: this.id,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            // console.log(this.interacao.model);
          } else {
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Interno":
          if (this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/internal",
                body: {
                  idTicket: this.id,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            // console.log(this.interacao.model);
          } else {
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Solucionar":
          if (this.isValidinteracao) {
            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/solved",
                body: {
                  idTicket: this.id,
                  msg: this.interacao.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });
            // console.log(this.interacao.model);
          } else {
            this.$v.interacao.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }
          break;
        case "Reprogramar":
          if (this.isValidReprogramar) {
            // corrige a data para o formato que o back entende
            var corrigeDate = this.reprogramar.date.model
              .split("-")
              .reverse()
              .join("/");

            this.$store
              .dispatch("postActions", {
                endpoint: "servicedesk/reprogram",
                body: {
                  idTicket: this.id,
                  date: corrigeDate,
                  time: this.reprogramar.hour.model
                }
              })
              .then(r => {
                this.$emit("fechar");
              });

            // console.log({
            //   from: "ReprogramarOut",
            //   r: {
            //     idTicket: this.id,
            //     date: corrigeDate,
            //     time: this.reprogramar.hour.model
            //   }
            // });
          } else {
            this.$v.reprogramar.date.$touch();
            this.$v.reprogramar.hour.$touch();
            this.$vs.notify({
              title: "Atenção!",
              text: "Você precisa preencher os campos corretamente.",
              position: "top-right",
              color: "rgb(242, 19, 93)"
            });
          }
          break;
      }
    },
    resetFields(fieldReq) {
      // console.log(fieldReq);
      switch (fieldReq) {
        case "segmento":
          this.encerrar.classEncerramento.options = [];
          this.encerrar.classEncerramento.model = "";
          this.encerrar.segmento.options = [];
          this.encerrar.segmento.model = "";
          this.encerrar.processo.options = [];
          this.encerrar.processo.model = "";
          break;
      }
    },
    getDados(obj) {
      var link = `servicedesk/consultdata?${
        obj.campo ? "field=" + obj.campo : ""
      }${obj.param ? "&search=" + obj.param : ""}`;

      if (!obj.reset) {
        this.resetFields(obj.campoVar);
      }

      this.$vs.loading({
        type: "radius",
        color: "#ff9c0b"
      });

      api
        .get(link)
        .then(r => {
          // console.log({ from: "getDados", r: r.data });

          if (r.data.item.length == 0) {
            this.$vs.notify({
              title: "Sem informação. ",
              text: "Esta categoria não possui dados.",
              position: "top-right",
              color: "rgb(255, 156, 11)"
            });
          }

          // console.log(fieldParaPesquisa.toLowerCase());
          var store = this[obj.acao];
          store[obj.campoVar].options = r.data.item;
          this.$vs.loading.close();
          return r.data.item;
        })
        .catch(r => {
          console.log({ from: "getDados", r });
          var store = this[obj.acao];
          store[obj.campoVar].model = "";
          this.$vs.loading.close();
        });
    }
  },
  computed: {
    token() {
      return window.localStorage.d;
    },
    legendas() {
      return this.$store.state.legends;
    },
    documents() {
      return this.$store.state.chamado.documents;
    },
    chamados() {
      return this.$store.state.chamados;
    },
    isValidEncerrar() {
      return !this.$v.encerrar.$invalid;
    },
    isValidDistribuir() {
      return !this.$v.distribuir.$invalid;
    },
    isValidinteracao() {
      return !this.$v.interacao.$invalid;
    },
    isValidReprogramar() {
      return !this.$v.reprogramar.$invalid;
    }
  },
  mounted() {}
};
</script>

<style lang="scss">
.form-popup {
  width: 90% !important;
}
.vs-select--label,
.vs-input--label {
  font-size: 16px !important;
}
</style>