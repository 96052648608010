<template>
  <div>
    <!-- inicio do chamado -->
    <div class="containerChamado p-8 shadow-card relative">
      <acoes :id="chamado.id" class="cursor-pointer absolute right-0 top-0 mt-6 mr-3" />
      <div class="header">
        <ul ref="ScrolPai" class="marquee" @mouseover="textScroll">
          <div class="flex" :class="scrollMouse ? 'ul' : ' '">
            <li class="item">
              <span>{{parseInt(chamado.id)}} -</span>
            </li>
            <li ref="ScrolFilho" class="item whitespace-no-wrap">
              <h1>{{chamado.title}}</h1>
            </li>
          </div>
        </ul>
        <VuePerfectScrollbar
          class="email-content-scroll-area"
          :settings="settings"
          style="max-height: 150px;"
        >
          <div class="flex flex-no-wrap">
            <div class="grup">
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Autor:</h3>
                </li>
                <li class="item">
                  <h2 class="text-17 font-bold cor-secundaria">{{chamado.author}}</h2>
                </li>
              </ul>
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Email:</h3>
                </li>
                <li class="item">
                  <p class="text-16 font-medium cor-secundaria">{{chamado.emailAuthor}}</p>
                </li>
              </ul>
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Tel:</h3>
                </li>
                <li class="item">
                  <p class="text-16 font-medium cor-secundaria">{{chamado.telephoneAuthor}}</p>
                </li>
              </ul>
            </div>
            <div class="grup">
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Analista:</h3>
                </li>
                <li class="item">
                  <h2 class="text-17 font-bold cor-secundaria">{{chamado.analyst}}</h2>
                </li>
              </ul>
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Email:</h3>
                </li>
                <li class="item">
                  <p class="text-16 font-medium cor-secundaria">{{chamado.emailAnalyst}}</p>
                </li>
              </ul>
              <ul class="flex items-center">
                <li class="item">
                  <h3 class="text-16 font-light cor-baixo-foco">Tel:</h3>
                </li>
                <li class="item">
                  <p class="text-16 font-medium cor-secundaria">{{chamado.telephoneAnalyst}}</p>
                </li>
              </ul>
            </div>
          </div>
        </VuePerfectScrollbar>
      </div>
    </div>

    <!-- detalhes -->
    <div class="containerChamado p-8 shadow-card flex-column">
      <span class="text-16 cor-baixo-foco">Detalhes:</span>
      <div class="container flex flex-column">
        <div class="p-1 pt-3">
          <span class="text-16 cor-secundaria">Descrição</span>
          <VuePerfectScrollbar
            class="email-content-scroll-area"
            :settings="settings"
            style="max-height: 150px;"
          >
            <p class="text-16 cor-baixo-foco">{{chamado.description}}</p>
          </VuePerfectScrollbar>
        </div>
      </div>
      <div class="container mt-3">
        <div class="flex flex-wrap justify-around flex-grow">
          <div
            v-for="(item, index) in chamado.item"
            :key="index"
            class="item flex flex-column m-3 aling-itens"
          >
            <span class="text-16 cor-secundaria">{{item.title}}</span>
            <span class="text-16 cor-baixo-foco">{{item.value}}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- interação -->
    <div class="containerChamado shadow-card flex-col">
      <div class="flex align-items-center shadow-card">
        <ul class="flex c p-2" style=" margin: 0 1rem 0 1rem;">
          <li class="item">
            <a
              @click="mudaSlideInteracao('Interação')"
              :class="interacaoActive == 'Interação' ? 'active' : '' "
            >Interação</a>
          </li>

          <li
            class="item"
            v-if="accessClient === 'ANALISTA' && chamado.colorStatus !== '01'  || accessClient === 'GESTOR SERVICE DESK' && chamado.colorStatus !== '01'"
          >
            <a
              @click="mudaSlideInteracao('Atendimento')"
              :class="interacaoActive == 'Atendimento' ? 'active' : '' "
            >Atendimento</a>
          </li>

          <li class="item" v-if="chamado.colorStatus === '01'">
            <a
              @click="mudaSlideInteracao('Editar')"
              :class="interacaoActive == 'Editar' ? 'active' : '' "
            >Editar</a>
          </li>

          <li
            class="item"
            v-if="accessClient === 'ANALISTA' || accessClient === 'GESTOR SERVICE DESK'"
          >
            <a
              @click="mudaSlideInteracao('Privado')"
              :class="interacaoActive == 'Privado' ? 'active' : '' "
            >Privado</a>
          </li>
        </ul>
      </div>
      <transition name="zoom-fade" mode="out-in">
        <Chat
          v-if="interacaoActive == 'Interação'"
          :mensagens="mensagensPublicas"
          :chamado="chamado"
        />
        <atendimento v-else-if="interacaoActive == 'Atendimento'" :chamado="chamado" />
        <editar v-else-if="interacaoActive == 'Editar'" :chamado="chamado" />
        <div v-else-if="interacaoActive == 'Privado'">
          <ChatP :mensagens="mensagensPrivadas" :chamado="chamado" />
        </div>
      </transition>
    </div>
  </div>
</template>
  
<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Chat from "./Chat";
import ChatP from "./ChatPrivate";
import acoes from "./acoes";
import atendimento from "./atendimento";
import editar from "./editar";
export default {
  components: {
    VuePerfectScrollbar,
    Chat,
    ChatP,
    atendimento,
    acoes,
    editar
  },
  data() {
    return {
      scrollMouse: false,
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      interacaoActive: "Interação"
    };
  },
  methods: {
    textScroll() {
      var tamanhoPai = this.$refs.ScrolPai.clientWidth;
      var tamanhoFilho = this.$refs.ScrolFilho.clientWidth;
      tamanhoPai < tamanhoFilho
        ? (this.scrollMouse = true)
        : (this.scrollMouse = false);
    },
    mudaSlideInteracao(val) {
      this.interacaoActive = val;
    }
  },
  computed: {
    mensagensPublicas() {
      return this.$store.state.chamado.chamadoInfo.interactionPublic;
    },
    mensagensPrivadas() {
      return this.$store.state.chamado.chamadoInfo.interactionPrivate;
    },
    chamado() {
      return this.$store.state.chamado.chamadoInfo;
    },
    accessClient() {
      return this.$store.state.info.user.type;
    }
  }
};
</script>

<style lang="scss">
.c {
  .item {
    padding: 15px 10px;
    list-style: none;
    transition: all 0.3s;
    a {
      color: #707070;
      font-size: 1.2rem;
      &.active {
        color: $CorPrimaria;
        padding-bottom: 22px;
        border-bottom: 3px solid $CorPrimaria;
      }
    }
  }
}

.containerChamado {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 20px;

  .header {
    display: flex;
    flex-direction: column;
    width: 100%;
    .marquee {
      overflow: hidden;
      width: 100%;
      &:hover .ul {
        animation: leftone 20s ease infinite;
      }
      @keyframes leftone {
        0% {
          transform: translateX(0%);
        }
        50% {
          transform: translateX(-100%);
        }
        100% {
          transform: translateX(-100%);
        }
      }
      .item {
        display: inline-flex;
        padding: 0.3rem;

        h1 {
          font-size: 23px;
          font-weight: 400;
          color: $CorBaixoFoco;
        }
        span {
          font-size: 20px;
          color: $CorBaixoFoco;
        }
      }
    }

    .grup {
      display: flex;
      flex-direction: column;
      margin: 10px 20px 0 0;
      .item {
        padding: 0.3rem;
      }
    }
  }
}
</style>