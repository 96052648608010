<template>
  <div class="login">
    <login />
    <vs-col v-if="!$store.state.mobile" class="bg" style="height: 100%;"></vs-col>
  </div>
</template>

<script>
import iconbtn from "../assets/login_bg.jpg";
import Login from "../components/LoginCard";
export default {
  components: {
    login: Login
  },
  mounted() {
    this.$initApp.ligar();
  }
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.bg {
  background-image: url("../assets/login_bg.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  position: absolute;
  filter: blur(3px) brightness(90%);
}

// .icon-btn {
//   width: 100%;
//   &::after {
//     position: relative;
//     background: url("../assets/icon-btn.svg") no-repeat center;
//     background-size: contain;
//     width: 22px;
//     height: 22px;
//     content: "";
//     vertical-align: middle;
//     display: inline-block;
//     margin-left: 15px;
//   }
// }
</style>