<template>
  <div
    class="bg-white"
    v-hammer:swipe.down="(e) => $router.push({ path: '/chamado', query: { ac: false } })"
  >
    <ul class="pt-5 list-mobile">
      <li
        v-if="accessAcoes.distribuir"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Distribuir')"
      >
        <span class="ml-2 text-CorPrimaria">Distribuir</span>
      </li>
      <li
        v-if="accessAcoes.associar"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Associar')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Associar</span>
      </li>
      <li
        v-if="accessAcoes.atender"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Atender')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Atender</span>
      </li>
      <li
        v-if="accessAcoes.externo"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Externo')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Externo</span>
      </li>
      <li
        v-if="accessAcoes.documentos"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Documentos')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Documentos</span>
      </li>
      <li
        v-if="accessAcoes.incluirDocumentos"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Adic. Documentos')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Adic. Documentos</span>
      </li>
      <li
        v-if="accessAcoes.interno"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Interno')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Interno</span>
      </li>
      <li
        v-if="accessAcoes.solucionar"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Solucionar')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Solucionar</span>
      </li>
      <li
        v-if="accessAcoes.encerrar"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Encerrar')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Encerrar</span>
      </li>
      <li
        v-if="accessAcoes.reabrir"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Reabrir')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Reabrir</span>
      </li>
      <li
        v-if="accessAcoes.cancelar"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Cancelar')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Cancelar</span>
      </li>
      <li
        v-if="accessAcoes.reprogramar"
        class="flex justify-center py-2 px-2 cursor-pointer border-grey-light border-b border-t-0 border-r-0 border-l-0 border-solid"
        @click="openModalAcoes('Reprogramar')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Reprogramar</span>
      </li>
      <li
        v-if="accessAcoes.legendas"
        class="flex justify-center py-2 px-2 cursor-pointer"
        @click="openModalAcoes('Legendas')"
      >
        <span class="ml-2 hover:text-CorPrimaria">Legendas</span>
      </li>
    </ul>
    <vs-popup v-if="destroyPopup" :title="tipoAcao" :active.sync="popupActive">
      <popupContent
        :id="id"
        :acao="tipoAcao"
        :chamadoInfo="chamadoInfo"
        @fechar="openModalAcoes('')"
      />
    </vs-popup>
  </div>
</template>

<script>
import popupContent from "./PopupAcoes";
import eventBus from "../eventBus";
export default {
  props: ["id"],
  components: {
    popupContent
  },
  data() {
    return {
      destroyPopup: true,
      popupActive: false,
      tipoAcao: ""
    };
  },
  methods: {
    openModalAcoes(acao) {
      this.tipoAcao = acao;
      this.popupActive = !this.popupActive;
      this.destroyPopup = true;
      this.$router.push({ path: "/chamado", query: { ac: false } });
    }
  },

  computed: {
    chamadoInfo() {
      return this.$store.state.chamado.chamadoInfo;
    },
    accessAcoes() {
      return this.$store.state.info.user.access;
    },
    activeAcoes2() {
      return this.$route.query.ac;
    }
  }
};
</script>

<style lang="scss">
.list-mobile {
  li {
    span {
      font-size: 1.2rem;
      &:hover {
        color: $CorPrimaria;
        text-shadow: 0px 3px 5px rgba($color: $CorPrimaria, $alpha: 0.4);
      }
    }
  }
}
</style>
