
<!-- hasSentPreviousMsg -->
<template>
  <div id="component-chat-log" class="m-8" v-if="chatData">
    <div v-for="(msg, index) in chatData" class="msg-grp-container" :key="index">
      <div class="flex items-start" :class="[{'flex-row-reverse' : verificaPessoa(msg.user)}]">
        <div
          class="msg break-words relative shadow rounded py-3 px-4 mb-2 rounded-lg max-w-sm flex flex-col"
          :class="{'bs-chat- text-white': verificaPessoa(msg.user), 'border border-solid border-grey-light bg-white': !verificaPessoa(msg.user)}"
          style="min-width: 350px"
        >
          <div class="flex justify-between">
            <span class="text-16 cor-secundaria">{{msg.user}}</span>
            <span class="cor-baixo-foco font-light">{{VerificaDiaDaMensagem(msg.date)}}</span>
          </div>

          <span>{{ msg.msg }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import dateFormat from "./dateFormat.js"

export default {
  props: ["chatData"],
  data() {
    return {};
  },
  computed: {
    // eslint-disable-next-line no-unused-vars
    hasSentPreviousMsg(last_sender, current_sender) {
      return (last_sender, current_sender) => last_sender == current_sender;
    },
    userAtivo() {
      var name = this.$store.state.info.user.name;
      var lastname = this.$store.state.info.user.lastname;
      return `${name} ${lastname}`;
    }
  },
  methods: {
    VerificaDiaDaMensagem(val) {
      return val
    },
    msgAnterior(anterior, atual) {
      if (atual == anterior) {
        return false;
      } else {
        return true;
      }
    },
    verificaPessoa(user) {
      if (user == "this.userAtivo") {
        return true;
      } else {
        return false;
      }
    },
    toDate(time) {
      const locale = "en-us";
      const date_obj = new Date(Date.parse(time));
      const monthName = date_obj.toLocaleString(locale, {
        month: "short"
      });
      return date_obj.getDate() + " " + monthName;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        this.$parent.$el.scrollTop = this.$parent.$el.scrollHeight;
      });
    }
  },
  updated() {
    this.scrollToBottom();
  },
  mounted() {
    this.scrollToBottom();
  }
};
</script>

<style lang="scss" >
.bs-chat- {
  background-color: #dcf8c6;
}
</style>