<template>
  <vs-row class="cardMobile" style="z-index: 2;" vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="4" vs-sm="5" vs-xs="12">
      <div class="card-login">
        <vs-row vs-justify="center">
          <img src="../assets/logo.png" />
        </vs-row>
        <form class="form">
          <label for="USERNAME" class="inp" :class="{'is-invalid': $v.username.$error }">
            <input
              type="text"
              id="USERNAME"
              placeholder=" "
              v-model.trim="username"
              @input="$v.username.$touch()"
            />
            <span class="label">E-mail</span>
            <span class="border"></span>
          </label>
          <label for="PASSWORD" class="inp" :class="{'is-invalid': $v.password.$error }">
            <input
              type="password"
              id="PASSWORD"
              placeholder=" "
              v-model.trim="password"
              @input="$v.password.$touch()"
            />
            <span class="label">Senha</span>
            <span class="border"></span>
            <vs-popup title="Insira seu email." :active.sync="popupActivo">
              <div class="flex flex-col justify-center aling-center">
                <vs-input
                  class="item-atendi self-center w-10/12"
                  label="Email"
                  placeholder="Email"
                  size="default"
                  type="email"
                  v-model="email.model"
                  :danger="$v.email.$error"
                  :danger-text="email.danger"
                  :success="!$v.email.$error && !$v.email.$invalid"
                  :success-text="email.success"
                />
                <vs-button
                  class="rounded-full w-1/3 self-center mt-5"
                  color="primary"
                  type="filled"
                  icon-pack="feather"
                  icon="icon-chevron-right"
                  icon-after
                  @click="forgotpassword"
                >Enviar</vs-button>
              </div>
            </vs-popup>
          </label>
        </form>
        <vs-row type="flex" vs-justify="start" vs-w="12">
          <a @click="esqueceuSenha">Esqueceu a senha?</a>
        </vs-row>
        <vs-row type="flex" vs-justify="center" vs-w="12">
          <button type="button" class="btn-login" @click="login">
            <span>Entrar</span>
            <i></i>
          </button>
        </vs-row>
      </div>
    </vs-col>
  </vs-row>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  data: () => {
    return {
      username: "",
      password: "",
      input: false,
      popupActivo: false,
      email: {
        model: "",
        danger: "Esse campo é obrigatório! E deve ser um email. ",
        success: "está tudo certo."
      }
    };
  },
  validations: {
    email: {
      model: {
        required,
        email
      }
    },
    username: {
      required
    },
    password: {
      required
    }
  },
  methods: {
    forgotpassword() {
      if (!this.$v.email.$invalid) {
        this.$store.dispatch("forgotpassword", { email: this.email.model });
      } else {
        this.$v.email.$touch();
      }
    },
    esqueceuSenha() {
      this.popupActivo = true;
      // this.$vs.dialog({
      //   color: "primary",
      //   title: "Esqueceu a senha",
      //   text:
      //     "Para alterar sua senha entre em contato com o Service Desk da DFS-Sistema, pelo telefone: 11 2408-6865"
      // });
    },
    login() {
      if (!this.$v.username.$invalid && !this.$v.password.$invalid) {
        this.$store.dispatch("login", {
          username: this.username,
          password: this.password,
          to: this.$route.query.to
        });
      } else {
        this.$v.username.$touch();
        this.$v.password.$touch();
        this.$vs.notify({
          title: "Campos",
          text: "Você precisa preencher os campos.",
          position: "top-right",
          color: "rgb(242, 19, 93)"
        });
      }
    }
  },
  computed: {
    isValid() {
      return !this.$v.$invalid;
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  color: $CorSecundaria;
  padding-left: 10px;
  cursor: pointer;
}
img {
  width: 400px;
}

@include media-down(sm) {
  .cardMobile {
    height: 100%;
  }
  .card-login {
    justify-content: center;
    height: 100%;
  }
}

.btn-login {
  display: flex;

  justify-content: space-between;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  width: 100%;
  box-shadow: 0px 5px 10px rgba(255, 156, 11, 0.5);
  background-color: $CorPrimaria;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 0.3s;
  text-shadow: 1px 1px 4px rgba(000, 000, 000, 0.3);
  margin-top: 2rem;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 16px rgba(255, 156, 11, 0.9);
  }
  i {
    position: relative;
    background: url("../assets/icon-btn.svg") no-repeat center;
    background-size: contain;
    width: 22px;
    height: 22px;
    vertical-align: middle;
    display: inline-block;
    margin-left: 15px;
  }
  span {
    color: #f5f6f8;
    align-self: center;
    flex: 1;
  }
}
.form {
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 20px;
  padding: 10px;

  .vs-input {
    border: none;
    width: 100%;
  }
}
.card-login {
  display: flex;
  flex-direction: column;
  background: #f5f6f8;
  padding: 2rem;
  border-radius: 14px;
}

.inp {
  position: relative;
  margin: auto;
  width: 100%;
  display: grid;
  margin: 1rem;
  &.is-invalid {
    .label {
      color: $erro;
    }
    input {
      border-bottom: 2px solid $erro;
    }
    .border {
      background: $erro;
    }
  }
  .label {
    position: absolute;
    top: 16px;
    left: 0;
    font-size: 14px;
    color: rgba(81, 92, 111, 0.5);
    font-weight: 500;
    transform-origin: 0 0;
    transition: all 0.2s ease;
  }
  .border {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 90%;
    background: $CorPrimaria;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: all 0.15s ease;
  }
  input {
    width: 90%;
    border: 0;
    font-family: inherit;
    padding: 12px 0;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    border-bottom: 2px solid #c8ccd4;
    background: none;
    border-radius: 0;
    color: $CorSecundaria;
    transition: all 0.15s ease;
    &:not(:placeholder-shown) + span {
      color: #5a667f;
      transform: translateY(-26px) scale(0.75);
    }
    &:focus {
      background: none;
      outline: none;
    }
    &:focus + span {
      color: $CorPrimaria;
      transform: translateY(-26px) scale(0.75);
    }
    &:focus + span + .border {
      transform: scaleX(1);
    }
  }
}
</style>