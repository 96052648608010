/* eslint-disable */
import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
import { api } from '@/services.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isPrimeiraEntrada: true,
    abaAtiva: "idle",
    loadingAppConnection: true,
    editPerfilBtn: false,
    token: "",
    login: {
      email: "",
      senha: ""
    },
    loadingChamado: true,
    chromeNavbarHeight: null,
    openMenuMobile: false,
    popupVisto: false,
    info: null,
    mobile: null,
    chamados: [],
    chamadosInfo: {},
    loadSpiner: false,
    legends: [],
    atualFiltroDeBusca: '',
    atualSearchDeBusca: '',
    parametroDePesquisa: '',
    chamado: {
      indicaChamadoAtivo: null,
      legendasChamado: [],
      chamadoActive: false,
      chamadoInfo: null,
      chamadoError: false,
      documents: null
    },
    resumoDados: false,
    fromPageMobileChamado: ""
  },
  mutations: {
    HANDLE(state, payload) {
      state[payload.key] = payload.value
    },

    BTN_EDIT_PERFIL_SET(state, payload) {
      state.editPerfilBtn = payload;
    },

    MOBILE_CHAMADO_FROM_PAGE(state, payload) {
      state.fromPageMobileChamado = payload.path;
    },

    ACTIVE_CHAMADO(state, payload) {
      state.chamado.indicaChamadoAtivo = payload;
    },

    UPDATE_LOGIN(state, payload) {
      state.usuario = payload;
      window.localStorage.d = `${payload.token}`;
    },

    SAIR_LOGIN(state) {
      state.usuario = null;
      window.localStorage.d = '';
      state.openMenuMobile = false;
    },

    UPDATE_USER_DADOS(state, payload) {
      state.info = payload;
      state.isPrimeiraEntrada = false;
    },

    UPDATE_CHAMADOS(state, payload) {
      state.chamados = payload.items.reverse();
    },

    UPDATE_CHAMADOS_DADOS(state, payload) {
      state.chamadosInfo.hasNext = payload.hasNext
      state.chamadosInfo.totalPages = payload.totalPages
      state.chamadosInfo.currentPage = payload.currentPage
      state.chamadosInfo.pageSize = payload.pageSize
      state.chamadosInfo.totalRecords = payload.totalRecords
    },

    UPDATE_CHAMADOS_PUSH(state, payload) {
      state.chamados.push(payload)
    },

    ACTIVE_MENU(state, payload) {
      state.openMenuMobile = payload;
    },

    UPDATE_CHAMADO_DETAILS(state, payload) {
      state.chamado.chamadoInfo = payload;
    },

    UPDATE_DOCUMENTOS(state, payload) {
      state.chamado.documents = payload;
    },

    CHAMADO_ACTIVE(state, payload) {
      state.chamado.chamadoActive = payload;
    },

    PUSH_CHAT(state, payload) {
      state.chamado.chamadoInfo.interactionPublic.push(payload)
    },

    PUSH_CHAT_PRIVATE(state, payload) {
      state.chamado.chamadoInfo.interactionPrivate.push(payload)
    },

    SWITCH_CHAMADO(state, payload) {
      state.abaAtiva = payload;
    },

    MOBILE(state, payload) {
      state.mobile = payload;
    },


    COLOR_PUSH(state, payload) {
      state.chamado.legendasChamado.push(payload);
      // console.log(payload)
    },

    RESUMO_DADOS_UPDATE(state, payload) {
      state.resumoDados = payload;
    },

    UPDATE_LEGENDS(state, payload) {
      state.legends = payload;
    },

    UPDATE_NAV_CHROME(state, payload) {
      state.chromeNavbarHeight = payload;
    }
  },
  actions: {


    //Função que determina o tamanho da navbar do Chrome para cálculos no mobile  
    chromeNavbarHeight(contex, payload) {
      var vhHeight = document.getElementsByTagName("body")[0].clientHeight;
      var chromeNavbarHeight = vhHeight - window.innerHeight;
      contex.commit('UPDATE_NAV_CHROME', chromeNavbarHeight);
    },


    // Função que trata as mensagens e envia para o backend e state 
    enviaNovaMensagemParaState(contex, payload) {
      var dNow = new Date();
      var localdate = dNow.getDate() + '/' + (dNow.getMonth() + 1) + '/' + dNow.getFullYear() + '-' + dNow.getHours() + ':' + dNow.getMinutes();

      this._vm.$vs.loading({
        type: "radius",
        color: "#ff9c0b"
      })

      api.post('servicedesk/interaction/', {
        idTicket: payload.idTicket,
        codeUser: payload.codeUser,
        emailUser: payload.emailUser,
        msg: payload.msg,
        type: payload.type,
        typeClosing: "",
        status: payload.status
      }).then(r => {
        console.log({ from: 'enviaNovaMensagemParaState', r })

        //Verifica se deu certo pela resposta status do back, só então envia para o state  
        if (r.data.status) {
          var msg = { user: payload.name, date: localdate, msg: payload.msg };
          payload.type == 'private' ? contex.commit("PUSH_CHAT_PRIVATE", msg) : contex.commit("PUSH_CHAT", msg);
        }

        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        contex.dispatch('UserInfoGet');
        // contex.commit("UPDATE_DOCUMENTOS", r.data.item);
      }).catch(r => {
        console.error({ from: 'interaction:200', r });
        this._vm.$vs.loading.close()
      })
    },


    //Função que verifica o tamanho da tela para ver se o app e compatível, e tambem verifica se é mobile 
    widthsize(contex) {
      //Verifica um range em pixel. 
      //Se for menor que 1000px e menor que 600px é mobile. 
      //Se for maior que 1000px e desktop. 
      //Se for menor que 1000px e maior que 600px não é suportado. 
      if (window.innerWidth < 1000) {

        if (window.innerWidth < 600) {
          contex.commit("MOBILE", true);
        } else {
          this._vm.$initApp.desligar({ tipo: "fechar", msg: "Opps essa resolução de tela não é suportada! :(" });
        }

      } else {
        contex.commit("MOBILE", false);
        if (!this.state.isPrimeiraEntrada) {
          this._vm.$initApp.ligar({ tipo: "ligar" });
        }

      }
    },


    //Responsável por limpar os dados da aplicação e voltar para o login 
    sairAplicacao(contex, payload) {
      contex.commit("SAIR_LOGIN");
      router.replace('/login');
      // document.location.reload();
    },


    //Responsável por logar o usuário 
    login(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })

      api.post(`user`, {
        user: payload.username,
        password: payload.password
      }).then(response => {
        // Verifica se foi autenticado
        if (response.data.authenticated) {
          this._vm.$vs.loading.close();
          contex.commit("UPDATE_LOGIN", response.data);
          contex.dispatch("UserInfoGet").then(r => {
            this._vm.$vs.loading.close();
            // verifica onde o usuario estava para retornar ao mesmo lugar
            if (payload.to == router.path) {
              router.push({ path: '/resumodados' });
            } else {
              router.push({ path: payload.to });
            }
          }).catch(r => {
            // caso de erro ao trazer os dados do usuario
            contex.dispatch('sairAplicacao');
            this._vm.$vs.loading.close();
          })
        } else {
          this._vm.$vs.loading.close();
        }
      }).catch(r => {
        // caso a chamada de login de errado
        this._vm.$vs.loading.close();
      })
    },


    //Responsável por trazer os dados do usuário 
    UserInfoGet(contex, payload) {
      return new Promise((resolve, reject) => {
        api.get("/user").then(r => {

          if (!r.data.authenticated) {
            // verifica se o token é valido
            reject({ authenticated: false });
            return
          }
          if (r.data.user == null) {
            console.warn('User não pode ser null!, voltando para login')
            contex.dispatch('sairAplicacao')
            return
          }
          contex.commit("UPDATE_USER_DADOS", r.data);
          // faz o pedido das legendas dos chamados
          contex.dispatch('getLegendas');
          resolve();

        }).catch((r) => {
          reject({ authenticated: false });
        })
      })
    },


    //Responsável por trazer o resumo de dados. 
    getResumoDados(contex, payload) {
      if (this.state.resumoDados.length) {
        this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })
      }
      api.get(`/datasummary`).then(r => {
        this._vm.$vs.loading.close();
        if (r.data.authenticated) {
          // verifica se o token é valido
          contex.commit("RESUMO_DADOS_UPDATE", r.data);
          return
        }

      }).catch(r => {
        this._vm.$vs.loading.close();
      })
    },


    //Responsável por trazer os detalhes do chamado para criar a tela do ticket. 
    getChamadosDetails(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })
      contex.commit("SWITCH_CHAMADO", "idle");

      api.get(`/servicedesk/detail?id=${payload}`).then(r => {
        this._vm.$vs.loading.close();
        // verifica se o token é valido
        if (r.data.authenticated) {
          contex.commit("UPDATE_CHAMADO_DETAILS", r.data);
          //verifica se esta no mobile ou descktop para direcionar a tela correta
          !contex.state.mobile ?
            contex.commit("SWITCH_CHAMADO", "chamado") :
            router.push({ name: "MobileChamado" });
          return
        }
      }).catch(r => {
        this._vm.$vs.loading.close();
      })
    },


    // chamada para trazer as legendas dos chamados
    getLegendas(contex, payload) {
      api.get(`/servicedesk/legend`).then(respostaLegends => {
        //envia os dados retornados para função que atribui as cores rgb
        contex.dispatch("statusChamado", respostaLegends.data.item);
      }).catch(r => {
        console.error(r)
      });
    },


    //Responsável por trazer os documentos do chamado selecionado
    getDocumentos(contex, payload) {
      api.get(`servicedesk/documents?idTicket=${payload}`).then(r => {
        contex.commit("UPDATE_DOCUMENTOS", r.data.item);
      }).catch(r => {
        console.error(r)
      })
    },


    //Responsável por trazer a lista de chamados
    getChamados(contex, payload) {
      var status = 'status='
      var search = '&search='

      status = 'status=' + contex.state.atualFiltroDeBusca 
      search = '&search=' + contex.state.atualSearchDeBusca

      var linkConstructor = `/servicedesk?${status}${payload.page ? '&page=' + payload.page : ''}${search}`

      api.get(linkConstructor).then(respostaChamados => {
        this._vm.$vs.loading.close();
        if (respostaChamados.data.authenticated) {
          // Verifica se tem uma próxima página e se tiver ele dá push na array ao invés de substituir os dados 
          if (payload.nextPage) {
            respostaChamados.data.items.forEach((chamado) => {
              contex.commit("UPDATE_CHAMADOS_PUSH", chamado);
            })
            contex.commit("UPDATE_CHAMADOS_DADOS", respostaChamados.data);
            // desliga o loading da lista
            contex.commit("HANDLE", { value: false, key: "loadSpiner" });
          } else {
            contex.commit("UPDATE_CHAMADOS", respostaChamados.data);
            contex.commit("UPDATE_CHAMADOS_DADOS", respostaChamados.data);
            contex.commit('HANDLE', { key: 'loadingChamado', value: false })
          }
        }
      }).catch(r => { console.log(r) })
    },


    //Responsável por gerar as cores das legendas 
    statusChamado(contex, payload) {
      var legendas = payload
      var geraCor = (cor) => {
        // var cor = '#5DC62F'
        return {
          background: cor,
          boxShadow: `0px 3px 6px ${cor}`
        }
      }
      var legendasOut = {}

      for (var legenda in legendas) {
        legendas[legenda].estilo = geraCor(legendas[legenda].initials)
        Object.defineProperty(legendasOut, legendas[legenda].color, {
          enumerable: true,
          configurable: true,
          writable: true,
          value: legendas[legenda]
        });
      }
      // console.log(legendasOut)
      contex.commit('UPDATE_LEGENDS', legendasOut)
    },


    //Responsável por fazer a requisição post de todas as interações de ação do ticket. 
    postActions(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" });
      return new Promise((resolve, reject) => {
        api.post(payload.endpoint, payload.body).then(r => {
          this._vm.$vs.loading.close()
          this._vm.$vs.notify({
            title: "Retorno",
            text: r.data.statusMsg,
            position: "top-right",
            time: 6000,
            color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
          });
          //Chama novamente as informações de usuario para atualizar os dados
          contex.dispatch('UserInfoGet');
          contex.dispatch('getChamados', { status: '', page: 1 })
          resolve()
        }).catch(r => {
          reject()
          this._vm.$vs.loading.close()
        })
      })
    },


    //Responsável por criar novos chamados 
    postCreate(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" });
      api.post('servicedesk/create', payload.body).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de usuario e os chamados
        contex.dispatch('UserInfoGet');
        contex.dispatch('getChamados', { status: '', page: 1 });
        //Retorna a parte dos chamados para o idle (foto de uma caixa de correio)
        contex.commit('SWITCH_CHAMADO', 'idle');
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    //Responsável por enviar as edições feita pela aba editar do chamado; vista apenas por aguardando distribuição. 
    chamadoAtt(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })
      api.post('servicedesk/alter', payload.body).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de usuario e os chamados
        contex.dispatch('UserInfoGet');
        contex.dispatch('getChamadosDetails', payload.body.idTicket);
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    //Responsável pelo post da aba atendimento 
    postAtendimento(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" });
      api.post('servicedesk/atendimento', payload.body).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de usuario e os chamados
        contex.dispatch('UserInfoGet');
        contex.dispatch('getChamadosDetails', payload.body.idTicket);
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    //Responsável pela mudança de senha 
    userAttSenha(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })
      api.put('user/alterpassword', payload).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de usuario
        contex.dispatch('UserInfoGet');
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    //Responsável pela mudança de foto
    userAttFoto(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" })
      api.put('user/alterphoto', payload).then(r => {
        console.log({ from: 'userAttFoto', r })
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de usuario
        contex.dispatch('UserInfoGet');
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    //Responsável por enviar os documentos
    uplDoc(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" });
      api.post('servicedesk/documents/', payload.dfs).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
        //Atualiza as informaçoes de documento
        contex.dispatch('getDocumentos', payload.id);
      }).catch(r => { this._vm.$vs.loading.close() })
    },


    ////Responsável por esqueci minha senha 
    forgotpassword(contex, payload) {
      this._vm.$vs.loading({ type: "radius", color: "#ff9c0b" });
      api.post('user/forgotpassword/', payload).then(r => {
        this._vm.$vs.loading.close()
        this._vm.$vs.notify({
          title: "Retorno",
          text: r.data.statusMsg,
          time: 6000,
          position: "top-right",
          color: r.data.status ? "#ff9c0b" : "rgb(242, 19, 93)"
        });
      }).catch(r => { this._vm.$vs.loading.close() })
    }
  }
})
