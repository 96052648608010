console.info('%c Created by %c SCS %c http://simplecyclestudio.com.br/ %c Version: 1.3.0 Commit: #bd8f1b7 Date: 2023-09-01 %c','background-color: #000000; color: #FFFFFF; line-height: 1.6em','background-color: #6D3581; color: #FFFFFF; line-height: 1.6em; font-weight: bold','background-color: none; color: #0000EE','background-color: #2A8DFF; color: #FFFFFF; line-height: 1.6em','background-color: none; color: #AAAAAA')

import Vue from 'vue'


// Raiz do projeto
import App from './App.vue'

// plugins base do sistema
import router from './router'
import store from './store'
import { api } from '@/services.js';
import './registerServiceWorker'
import eventBus from './eventBus.js'
Vue.use(eventBus)

import initApp from './initApp'
Vue.use(initApp)

// Responsavel pelos gestos no mobile
// import { VueHammer } from 'vue2-hammer'
// Vue.use(VueHammer)

// Vuelidate validação de formularios
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate)

// Swiper plugin
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// formatacao de data
import VueFilterDateFormat from './dateFormat';
Vue.use(VueFilterDateFormat, {
  monthNames: [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ],
  monthNamesShort: [
    'jan', 'fev', 'mar', 'abr', 'maio', 'jun',
    'jul', 'ago', 'set', 'out', 'nov', 'dez'
  ]
});

// estilos tailwind e scss personalizados 
import './assets/scss/main.scss'
import '@/assets/css/main.css';
require('./assets/css/iconfont.css')

// Vuesax framework de estilos
import Vuesax from 'vuesax'
Vue.use(Vuesax, {
  theme: {
    colors: {
      primary: '#ff9c0b',
    }
  }
});
import 'vuesax/dist/vuesax.css'

// componentes globais
import './globalComponent'
import './filters'

//intansia Vue
Vue.config.productionTip = false
const ds = new Vue({
  router,
  store,
  api,
  initApp,
  render: h => h(App)
}).$mount('#app');

export default ds