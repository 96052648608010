<template>
  <VuePerfectScrollbar
    class="email-content-scroll-area flex flex-col"
    :settings="settings"
    v-if="chamados.length !== 0 && !loading"
    @ps-y-reach-end="NextPageLoad"
  >
    <transition-group id="list-ticket" name="list-enter-up" class="email__mails" tag="ul" appear>
      <li
        class="chamadoItem cursor-pointer"
        v-for="(chamado, index) in chamados"
        :key="index"
        :style="{transitionDelay: (index / 50) + 's'}"
        :class="activeChamado == index ? 'active' : ' '"
        @click="pushChamado(chamado.id, index)"
      >
        <div class="chamadoItemContainer">
          <div class="titulo">
            <vs-tooltip position="left" :text="chamado.title">
              <span class="text-17 font-bold cor-secundaria">{{chamado.title | limitCharac(25)}}</span>
            </vs-tooltip>

            <span class="text-16">{{chamado.description | limitCharac(100)}}</span>
          </div>
          <div class="infor">
            <div class="data">{{chamado.openDate | dateFormat('DD/MM/YY')}}</div>
            <feather-icon icon="LinkIcon" class="arquivo" v-if="chamado.attachments"></feather-icon>

            <div class="numero">#{{parseInt(chamado.id)}}</div>
            <div class="cor">
              <vs-tooltip position="left" :text="legendasChamado[chamado.colorStatus].description">
                <span
                  class="icone-status-chamado"
                  :style="legendasChamado[chamado.colorStatus].estilo"
                ></span>
              </vs-tooltip>
            </div>
          </div>
        </div>
      </li>
    </transition-group>
    <transition-group name="fade-up" tag="div" appear class="flex self-center">
      <div key="1" class="lds-ellipsis" v-if="loadSpiner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </transition-group>
  </VuePerfectScrollbar>
  <div v-else class="self-center flex flex-col">
    <loading :height="400" :width="400" />
    <span
      v-if="chamados.length == 0 && !loading"
      class="text-17 font-bold cor-secundaria text-center"
    >Ops.. Não encontramos o ticket pesquisado.</span>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      corChamado: [],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    loadSpiner: {
      get() {
        return this.$store.state.loadSpiner;
      },
      set(value) {
        this.$store.commit("HANDLE", { value, key: "loadSpiner" });
      }
    },
    loading: {
      get() {
        return this.$store.state.loadingChamado;
      },
      set(value) {
        this.$store.commit("HANDLE", { value, key: "loadingChamado" });
      }
    },
    activeChamado() {
      return this.$store.state.chamado.indicaChamadoAtivo;
    },
    chamados() {
      return this.$store.state.chamados;
    },
    legendasChamado() {
      return this.$store.state.legends;
    },
    chamadosInfo() {
      return this.$store.state.chamadosInfo;
    },
    nameRouter() {
      return this.$route.path;
    }
  },
  methods: {
    NextPageLoad() {
      if (!this.chamadosInfo.hasNext) {
        return;
      }
      setTimeout(() => {
        this.loadSpiner = true;
        this.getData();
      }, 500);
      // console.log(this.chamados);
    },
    pushChamado(id, index) {
      this.$store.commit("ACTIVE_CHAMADO", index);
      this.$store.dispatch("getChamadosDetails", id);
      this.$store.dispatch("getDocumentos", id);
    },
    getData(typeChamados) {
      var params = {
        qtd: 10,
        status: typeChamados,
        page: this.nextPage
      };

      if (this.chamados.length == 0) {
        this.loading = true;
        params.page = 1;
      } else {
        params.page = this.chamadosInfo.currentPage;
        params.page++;
        params.nextPage = true;
      }

      this.$store.dispatch("getChamados", params);
    }
  },
  mounted() {
    if (this.chamados.length == 0) {
      this.getData();
    }
  }
};
</script>

<style lang="scss" >
.lds-ellipsis {
  display: inline-block;
  position: fixed;
  width: 80px;
  height: 80px;
  bottom: 0px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ff9c0b;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.email-scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: calc(100% - 75px);
}

.email-content-scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: 100%;
  @include media-down(md) {
    .ps__rail-y {
      display: none !important;
    }
  }
}

.icone-status-chamado {
  font-size: 13px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.3rem;
  color: #fff;
  height: 18px;
  width: 18px;
  vertical-align: middle;
  display: flex;
}

.email__mails {
  margin: 0 0.8rem;

  .chamadoItem {
    transition: all 0.2s;
    &.active {
      .chamadoItemContainer {
        transform: translateY(-4px);
        box-shadow: 0px 3px 10px 0px #ccc;
        transition: all 0.2s;
      }
    }
    .chamadoItemContainer {
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 5fr 1fr;
      grid-template-areas: "titulo infor";
      padding: 0.8rem 0 0.8rem 0.8rem;
      border-radius: 20px;
      border-top: 1px solid #dae1e7;

      &:hover {
        transform: translateY(-4px);
        box-shadow: 0px 3px 10px 0px #ccc;
        transition: all 0.2s;
      }

      .titulo {
        grid-area: titulo;
        display: flex;
        flex-direction: column;
        @include media-down(xl) {
          max-width: 200px;
        }
      }

      .infor {
        grid-area: infor;
        display: grid;
        grid-gap: 5px;
        justify-self:end;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas:
          "data data"
          "arquivo cor"
          "numero  numero";
        .data {
          grid-area: data;
          font-size: 1rem;
          color: $CorSecundaria;
        }
        .arquivo {
          grid-area: arquivo;
          width: 18px;
        }
        .numero {
          grid-area: numero;
          font-size: 1rem;
          color: $CorSecundaria;
        }
        .cor {
          grid-area: cor;
        }
      }
    }
  }
}
</style>