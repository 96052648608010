<template>
  <mobile v-if="$store.state.mobile"></mobile>
  <desktop v-else></desktop>
</template>

<script>
import navmobile from "./Mobile/navbar";
import navdesktop from "./Desktop/navbar";
export default {
  components: {
    mobile: navmobile,
    desktop: navdesktop
  },
  data: () => {
    return {};
  }
};
</script>

<style lang="scss">
</style>