import axios from "axios";
import ds from './main'

const http = axios.create({
 /*  baseURL: "https://dfssistemas.ddns.net:8866/rest/appticketdfs/v1/" */
 baseURL: "https://servicos.protheus.dfssistemas.com.br:8866/rest/appticketdfs/v1/"
  // baseURL: 'https://177.144.132.56:8866/rest/appticketdfs/v1/'
  // baseURL: "http://192.168.0.8:3005"
});

const ipify = axios.create({
  baseURL: "https://api.ipify.org"
});

http.interceptors.request.use((config) => {
  const token = window.localStorage.d;
  config.headers.XToken = '1234567890'
  config.headers.Authorization = `Basic ZmFiaW8uZGVzazphYmMxMjM=`
  if (token) {
    config.headers.token = token
  }
  return config;
}
)

http.interceptors.response.use((response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  console.log({ from: response.config.url, r: JSON.parse(response.request.response) })

  if (response.authenticated === undefined) {
    if (!response.data.authenticated) {
      console.log('oi')
      ds.$vs.notify({
        title: "Erro de autenticação",
        text: response.data.statusMsg,
        time: 6000,
        position: "top-right",
        color: "rgb(242, 19, 93)"
      });
      ds.$store.dispatch('sairAplicacao');
    }
  } else {
    if (!response.authenticated) {
      console.log('oi2')
      ds.$vs.notify({
        title: "Erro de autenticação",
        text: response.statusMsg,
        time: 6000,
        position: "top-right",
        color: "rgb(242, 19, 93)"
      });
      ds.$store.dispatch('sairAplicacao');
    }
  }

  return response;

}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  console.error({ from: error.config.url, r: JSON.parse(error.response.request.response) })
  switch (error.response.status) {
    case 404:
      ds.$vs.notify({
        title: "Erro",
        text: "Erro 404",
        position: "top-right",
        color: "rgb(242, 19, 93)"
      });
      break;
    case 500:
      ds.$vs.notify({
        title: "Erro",
        text: "Erro no servidor.",
        position: "top-right",
        color: "rgb(242, 19, 93)"
      });
      break;
    default:
      ds.$vs.notify({
        title: "Erro",
        text: "Não foi possível realizar as ações.",
        position: "top-right",
        color: "rgb(242, 19, 93)"
      });
      break;
  }
  return Promise.reject(error);
});

export const api = {
  get(endpoint) {
    return http.get(`${endpoint}`);
  },
  post(endpoint, body) {
    return http.post(endpoint, body);
  },
  put(endpoint, body) {
    return http.put(endpoint, body);
  },
  delete(endpoint, body) {
    return http.delete(endpoint, body);
  },
  busca(endpoint) {
    return http.get(endpoint);
  },
  getIp(endpoint) {
    return ipify.get(`${endpoint}`);
  }

}