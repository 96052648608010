<template>
  <div class="chat__bg fixed-height chat-content-area flex items-center justify-center">
    <VuePerfectScrollbar class="chat-content-scroll-area" :settings="settings">
      <div class="chat__log scroll-" ref="chatLog">
        <chat-log v-if="mensagens" :chatData="mensagens" />
      </div>
    </VuePerfectScrollbar>
    <div class="chat__input flex p-4 bg-white">
      <vs-textarea
        class="flex-1"
        placeholder=" "
        v-model="typedMessage"
        style="margin-bottom: 0;"
      />
      <vs-button class="bg-primary-gradient ml-4 h-full" type="filled" @click="sendMsg">Enviar</vs-button>
    </div>

    <vs-popup title="Status" :active.sync="popupActivo">
      <div class="flex flex-col justify-center aling-center">
        <vs-select
          class="item-atendi self-center"
          label="Status"
          :disabled="status.options.length == 0"
          :danger="$v.status.$error"
          :danger-text="status.danger"
          width="400px"
          autocomplete
          v-model="status.model"
        >
          <vs-select-item
            :key="index"
            :value="item.id"
            :text="item.description"
            v-for="(item,index) in status.options"
          />
        </vs-select>

        <vs-button
          class="rounded-full w-1/3 self-center mt-5"
          color="primary"
          type="filled"
          icon-pack="feather"
          icon="icon-chevron-right"
          icon-after
          @click="sendMsgAnalist"
        >Enviar</vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import ChatLog from "./ChatLog.vue";
import { required } from "vuelidate/lib/validators";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  props: ["mensagens", "chamado"],
  components: {
    VuePerfectScrollbar,
    ChatLog
  },
  data() {
    return {
      active: true,
      typedMessage: null,
      popupActivo: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.7
      },
      clickNotClose: true,
      windowWidth: window.innerWidth,

      status: {
        model: "",
        options: [
          { id: "AGUARDANDO ANALISTA", description: "AGUARDANDO ANALISTA" },
          { id: "AGUARDANDO USUARIO", description: "AGUARDANDO USUARIO" }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      }
    };
  },
  validations: {
    status: {
      model: {
        required
      }
    }
  },
  computed: {
    userAtivo() {
      var name = this.$store.state.info.user.name;
      var lastname = this.$store.state.info.user.lastname;
      return `${name} ${lastname}`;
    },
    isAtendente() {
      if (
        this.$store.state.info.user.type == "ANALISTA" ||
        this.$store.state.info.user.type == "GESTOR SERVICE DESK"
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    sendMsgAnalist() {
      if (!this.$v.status.$invalid) {
        this.$store.dispatch("enviaNovaMensagemParaState", {
          name: this.userAtivo,
          msg: this.typedMessage,
          idTicket: this.chamado.id,
          codeUser: this.$store.state.info.user.id,
          emailUser: this.$store.state.info.user.email,
          status: this.status.model,
          type: "public"
        });
        this.typedMessage = "";
        this.status.model = "";
        this.popupActivo = false;
      } else {
        this.$v.status.$touch();
      }
    },
    sendMsg() {
      if (this.typedMessage.length === 0 || !this.typedMessage.trim()) {
        return
      } else {
        if (this.isAtendente) {
          if (this.chamado.colorStatus == "06") {
            this.$store.dispatch("enviaNovaMensagemParaState", {
              name: this.userAtivo,
              msg: this.typedMessage,
              idTicket: this.chamado.id,
              codeUser: this.$store.state.info.user.id,
              emailUser: this.$store.state.info.user.email,
              status: "",
              type: "public"
            });
            this.typedMessage = "";
          } else {
            this.popupActivo = true;
          }
        } else {
          this.$store.dispatch("enviaNovaMensagemParaState", {
            name: this.userAtivo,
            msg: this.typedMessage,
            idTicket: this.chamado.id,
            codeUser: this.$store.state.info.user.id,
            emailUser: this.$store.state.info.user.email,
            status: "",
            type: "public"
          });
          this.typedMessage = "";
        }
      }
    }
  }
};
</script>
<style lang="scss">
.scroll-{
  overflow-y: auto;
  max-height: 500px;
}
.fixed-height {
  height: 100%;
}
</style>