<template>
  <div>
    <transition name="fade" mode="out-in">
      <div v-if="retornaSeTemDados">
        <Nav />
        <transition name="slide-fade">
          <router-view class="container-view" />
        </transition>
      </div>
      <!-- <loading :height="400" :width="400" class="loading" v-else /> -->
    </transition>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    Nav: NavBar,
  },
  methods: {
    verificaDadosClienteParaLiberarAccess() {
      if (navigator.onLine) {
        if (this.retornaSeTemDados) {
          this.$initApp.ligar();
        } else {
          this.$initApp.desligar({
            msg: "Trazendo dados do servidor.",
          });
          this.$store
            .dispatch("UserInfoGet")
            .then(() => {
              this.$initApp.ligar();
            })
            .catch((r) => {
              if (!r.authenticated) {
                this.$store.dispatch("sairAplicacao");
                this.$initApp.ligar();
              } else {
                this.$initApp.desligar({
                  msg: "Tentando conectar com o servidor.",
                });
                setTimeout(() => {
                  this.verificaDadosClienteParaLiberarAccess();
                }, 3000);
              }
              console.log(r);
            });
        }
      } else {
        this.$initApp.desligar({
          msg: "Oops parece que você nao esta conectado a internet. :(",
        });
        setTimeout(() => {
          this.verificaDadosClienteParaLiberarAccess();
        }, 1000);
      }
    },
  },
  computed: {
    retornaSeTemDados() {
      return this.$store.state.info;
    },
  },
  mounted() {
    this.verificaDadosClienteParaLiberarAccess();
  },
};
</script>

<style>
.container-view {
  position: absolute !important;
  width: 100vw;
  bottom: 0;
}
.loading {
  align-self: center;
  width: 500px;
}
</style>
