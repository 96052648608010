<template>
  <div class>
    <div class="flex justify-between m-5 md:m-10">
      <ul class="flex flex-col">
        <li class="text-16 font-light cor-secundaria">Entidade:</li>
        <li class="text-17 cor-secundaria">{{chamado.Entity}}</li>
      </ul>
      <ul class="flex flex-col">
        <li class="text-16 font-light cor-secundaria">Grupo:</li>
        <li class="text-17 cor-secundaria">{{chamado.Group}}</li>
      </ul>
      <ul class="flex flex-col">
        <li class="text-16 font-light cor-secundaria">Sub-Grupo:</li>
        <li class="text-17 cor-secundaria">{{chamado.subGroup}}</li>
      </ul>
    </div>

    <div class="flex flex-wrap m-5 md:m-8">
      <vs-select
        class="item-atendi"
        label="Categoria"
        :danger="$v.categoria.$error"
        :danger-text="categoria.danger"
        autocomplete
        v-model="categoria.model"
        @input="getDados({campo: 'PLATAFORMA',campoVar: 'plataforma',param: `${categoria.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in categoria.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Plataforma"
        :disabled="plataforma.options.length == 0"
        :danger="$v.plataforma.$error"
        :danger-text="plataforma.danger"
        autocomplete
        v-model="plataforma.model"
        @input="getDados({campo: 'ASSUNTO',campoVar: 'modulo',param: `${categoria.model + plataforma.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in plataforma.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Modulo/Segmento"
        :disabled="modulo.options.length == 0"
        :danger="$v.modulo.$error"
        :danger-text="modulo.danger"
        autocomplete
        v-model="modulo.model"
        @input="getDados({campo: 'PROCESSO',campoVar: 'processo',param: `${categoria.model + plataforma.model + modulo.model}`,})"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in modulo.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Processo"
        :disabled="processo.options.length == 0"
        :danger="$v.processo.$error"
        :danger-text="processo.danger"
        autocomplete
        v-model="processo.model"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in processo.options"
        />
      </vs-select>

      <vs-select
        class="item-atendi"
        label="Prioridade"
        :disabled="prioridade.options.length == 0"
        :danger="$v.prioridade.$error"
        :danger-text="prioridade.danger"
        autocomplete
        v-model="prioridade.model"
      >
        <vs-select-item
          :key="index"
          :value="item.id"
          :text="item.description"
          v-for="(item,index) in prioridade.options"
        />
      </vs-select>

      <vs-input
        class="item-atendi"
        label="Observador"
        placeholder="Email"
        size="default"
        v-model="observador.model"
        :danger="$v.observador.$error"
        :danger-text="observador.danger"
        :success="!$v.observador.$error && !$v.observador.$invalid"
        :success-text="observador.success"
      />

      <vs-input
        class="item-atendi"
        label="Interação"
        placeholder="Interação"
        size="large"
        v-model="interacao.model"
        :danger="$v.interacao.$error"
        :danger-text="interacao.danger"
        :success="!$v.interacao.$error && !$v.interacao.$invalid"
        :success-text="interacao.success"
      />
    </div>

    <div class="flex justify-center aling-center pb-10">
      <vs-button
        class="rounded-full"
        color="primary"
        type="filled"
        icon-pack="feather"
        icon="icon-chevron-right"
        icon-after
        @click="attChamado"
      >Atualizar</vs-button>
    </div>
  </div>
</template>

<script>
import { api } from "../services";
import { required } from "vuelidate/lib/validators";
export default {
  props: ["chamado"],
  data() {
    return {
      categoria: {
        model: this.chamado.idCategory,
        options: this.getDados({
          campo: "CATEG",
          campoVar: "categoria",
          reset: true
        }),
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      plataforma: {
        model: this.chamado.idPlatform,
        options: [
          {
            id: this.chamado.idPlatform,
            description: this.chamado.platform
          }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      modulo: {
        model: this.chamado.idSubject,
        options: [
          {
            id: this.chamado.idSubject,
            description: this.chamado.subject
          }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      processo: {
        model: this.chamado.idProcess,
        options: [
          {
            id: this.chamado.idProcess,
            description: this.chamado.process
          }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      prioridade: {
        model: this.chamado.priority,
        options: [
          { id: "3", description: "Baixa" },
          { id: "2", description: "Média" },
          { id: "1", description: "Alta" }
        ],
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      observador: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      },

      interacao: {
        model: "",
        danger: "Esse campo é obrigatório! ",
        success: "está tudo certo."
      }
    };
  },

  validations: {
    categoria: {
      model: {
        required
      }
    },
    interacao: {
      model: {
        required
      }
    },
    plataforma: {
      model: {
        required
      }
    },
    modulo: {
      model: {
        required
      }
    },
    processo: {
      model: {
        required
      }
    },
    prioridade: {
      model: {
        required
      }
    },
    observador: {
      model: {
        required
      }
    }
  },

  computed: {
    isValidCategoria() {
      return !this.$v.categoria.$invalid;
    },
    isValidPlataforma() {
      return !this.$v.plataforma.$invalid;
    },
    isValidModulo() {
      return !this.$v.modulo.$invalid;
    },
    isValidProcesso() {
      return !this.$v.processo.$invalid;
    },
    isValidPrioridade() {
      return !this.$v.prioridade.$invalid;
    },
    isValidinteracao() {
      return !this.$v.interacao.$invalid;
    }
  },

  methods: {
    attChamado() {
      if (
        this.isValidCategoria &&
        this.isValidPlataforma &&
        this.isValidModulo &&
        this.isValidProcesso &&
        this.isValidPrioridade &&
        this.isValidinteracao
      ) {
        this.$store.dispatch("postAtendimento", {
          body: {
            idTicket: this.chamado.id,
            msg: this.interacao.model,
            priority: this.prioridade.model,
            idCategory: this.categoria.model,
            idPlatform: this.plataforma.model,
            idSegment: this.modulo.model,
            idProcess: this.processo.model,
            idUserObs: this.observador.model,
            emailInteraction: false
          }
        });
      } else {
        this.$v.processo.$touch();
        this.$v.modulo.$touch();
        this.$v.plataforma.$touch();
        this.$v.categoria.$touch();
        this.$v.prioridade.$touch();
        this.$v.interacao.$touch();
      }
    },

    resetFields(fieldReq) {
      switch (fieldReq) {
        case "categoria":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "plataforma":
          this.plataforma.options = [];
          this.plataforma.model = "";
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        case "modulo":
          this.modulo.options = [];
          this.modulo.model = "";
          this.processo.options = [];
          this.processo.model = "";
          break;
        default:
          break;
      }
    },

    getDados(obj) {
      var link = `servicedesk/consultdata?${
        obj.campo ? "field=" + obj.campo : ""
      }${obj.param ? "&search=" + obj.param : ""}`;

      if (!obj.reset) {
        this.resetFields(obj.campoVar);
      }

      this.$vs.loading({
        type: "radius",
        color: "#ff9c0b"
      });

      api
        .get(link)
        .then(r => {
          if (r.data.item.length == 0 || JSON.stringify(r.data.item) === "{}") {
            this.$vs.notify({
              title: "Sem informação. ",
              text: "Essa categoria não possui dados. ",
              position: "top-right",
              color: "rgb(255, 156, 11)"
            });
            this.$vs.loading.close();
            return;
          }

          // console.log(fieldParaPesquisa.toLowerCase());
          this[obj.campoVar].options = r.data.item;
          this.$vs.loading.close();
          return r.data.item;
        })
        .catch(r => {
          console.log({ from: "getDados", r });
          this[obj.campoVar].model = "";
          this.$vs.loading.close();
        });
    }
  }
};
</script>

<style lang="scss">
.item-atendi {
  flex: 1 1 40%;
  margin: 5px;
}
</style>