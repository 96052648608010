<template>
  <router-view
    v-if="fechaApp"
  />
  <!-- <router-view
    v-if="fechaApp"
    v-hammer:swipe.left="(event) => $store.commit('ACTIVE_MENU', false)"
    v-hammer:swipe.right="(event) => $store.commit('ACTIVE_MENU', true)"
  /> -->
</template>

<script>
export default {
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.chromeNavbarHeight();
  },
  // mounted() {
 
  // },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  computed: {
    fechaApp() {
      return this.$store.state.loadingAppConnection;
    },
  },
  methods: {
    chromeNavbarHeight() {
      this.$store.dispatch("chromeNavbarHeight");
    },
    handleResize() {
      this.$store.dispatch("widthsize");
    },
  },
};
</script>

<style lang="scss">
span {
  color: #707070;
}

.flex {
  display: flex;
}
.aling-itens {
  align-items: center;
}
.coluna {
  flex-direction: column;
}
.background-primary {
  background-color: $CorPrimaria;
}
.texto_primario {
  color: $CorPrimaria;
}
.after-none {
  display: block !important;
}
// .swiper-wrapper {
//   position: absolute !important;
//   width: 260px !important;
// }
</style>
